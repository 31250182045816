import React from "react";

import Box from "@material-ui/core/Box";
import MUIPaper from "@material-ui/core/Paper";

export default function Paper({ children, ...paperProps }) {
  return (
    <MUIPaper component={Box} {...paperProps}>
      {children}
    </MUIPaper>
  );
}
