import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import { useAuthContext } from "context/auth";
import { CampaignsProvider } from "context/campaigns";
import { CampaignDetailsProvider } from "context/campaignDetails";
import { MetricsProvider } from "context/metrics";
import { ROITrackerProvider } from "context/roiTracker";
import { DashboardLayoutProvider } from "context/dashboardLayout";

import HomePage from "./HomePage";
import DashboardLayout from "./DashboardLayout";
import LoginPage from "./LoginPage";
import Metrics from "./Metrics";
import ROITracker from "./ROITracker";
import { dashboardTheme, loginTheme } from "./theme";

export default function App() {
  const { authState } = useAuthContext();

  const theme = React.useMemo(() => {
    if (authState.user) {
      return responsiveFontSizes(createTheme(dashboardTheme));
    }
    return responsiveFontSizes(createTheme(loginTheme));
  }, [authState.user]);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        {authState.user ? (
          <Router>
            {/*
              CampaignsProvider holds the list of all the user's Campaigns.
              TODO: Update this if the user changes the campaign details (ie, start/end date)
            */}
            <CampaignsProvider>
              <Switch>
                <Route path="/" exact>
                  {/* This Layout will only meaningfully render for mobile and tablet, and doesn't need `campaignId` param. */}
                  <DashboardLayoutProvider>
                    <DashboardLayout>
                      <HomePage />
                    </DashboardLayout>
                  </DashboardLayoutProvider>
                </Route>
                {/*
                  Nesting routes like this gives DashboardLayout access to `campaignId` param,
                  without having to re-render it when changing from metrics to roi-tracker
                */}
                <Route path="/campaigns/:campaignId">
                  <DashboardLayoutProvider>
                    <DashboardLayout>
                      <CampaignDetailsProvider>
                        <Switch>
                          <Route path="/campaigns/:campaignId/roi-tracker">
                            <ROITrackerProvider>
                              <ROITracker />
                            </ROITrackerProvider>
                          </Route>
                          <Route path="/campaigns/:campaignId/metrics">
                            <MetricsProvider>
                              <Metrics />
                            </MetricsProvider>
                          </Route>
                        </Switch>
                      </CampaignDetailsProvider>
                    </DashboardLayout>
                  </DashboardLayoutProvider>
                </Route>
                {/* TODO: Make a real 404 Page */}
                <div>404</div>
              </Switch>
            </CampaignsProvider>
          </Router>
        ) : (
          <LoginPage />
        )}
        <ToastContainer />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
