import React, { useContext, useReducer } from "react";

import { useMount } from "utils";
import { getCampaigns } from "./actions";
import { reducer, initialState } from "./reducer";

const CampaignsContext = React.createContext();

export function CampaignsProvider({ children }) {
  const [campaignsState, campaignsDispatch] = useReducer(reducer, initialState);
  useMount(() => {
    getCampaigns(campaignsDispatch);
  });

  return (
    <CampaignsContext.Provider value={{ campaignsState, campaignsDispatch }}>
      {children}
    </CampaignsContext.Provider>
  );
}

export function useCampaignsContext() {
  const context = useContext(CampaignsContext);
  if (context === undefined) {
    throw new Error(
      "useCampaignsContext must be used within an CampaignsProvider"
    );
  }
  return context;
}
