import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { usePreCampaignWizardContext } from "context/preCampaignWizard";
import { Paper } from "atoms";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { gear } from "img";
import { DownloadStoreListButton } from "atoms/campaign";

const useStyles = makeStyles((theme) => ({
  smallGutterBottom: {
    marginBottom: theme.spacing(3),
  },
}));

export default function WizardCompleteConfirmationPage() {
  const { wizardUtils } = usePreCampaignWizardContext();
  const {
    campaignDetailsState: { campaignDetails },
  } = useCampaignDetailsContext();
  const classes = useStyles();

  const overridden = campaignDetails.roi_state === "NEEDS_OVERRIDE";

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Paper
        variant="outlined"
        width={overridden ? 486 : 424}
        height={overridden ? 600 : 500}
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={3}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          width={308}
        >
          <Box mb={3}>
            <img src={gear.src} aria-label={gear.ariaLabel} width={121} />
          </Box>
          <Box mb={3}>
            <Typography component="h2" variant="h3" align="center">
              Almost There!
            </Typography>
          </Box>
          {overridden ? (
            <>
              <Typography
                align="center"
                variant="body1"
                className={classes.smallGutterBottom}
              >
                To complete the Pre-campaign set up with your test and control
                store list, please send your store list breakdown to your
                genesis account representative. They will use this list to
                finalize your pre-campaign set up and as guidance for serving
                media.
              </Typography>
              <Typography align="center" variant="body1">
                Please allow 1-2 days for your control list data to be reflected
                in the system.
              </Typography>
            </>
          ) : (
            <Typography align="center" variant="body1">
              Now that you have aligned your Test and Control List Plan, please
              Download the file by using the prompt below, and send the file to
              your GENESIS Rep.
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            mt={4}
          >
            {!overridden && (
              <DownloadStoreListButton
                variant="contained"
                color="secondary"
                size="large"
                className={classes.successButton}
              >
                Download
              </DownloadStoreListButton>
            )}
            <Box mt={2}>
              <Button
                size="large"
                variant={
                  campaignDetails.roi_state === "NEEDS_OVERRIDE"
                    ? "contained"
                    : "outlined"
                }
                color="primary"
                onClick={wizardUtils.finishWizard}
              >
                Continue Setup
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
