import clsx from "clsx";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { useMetricsContext } from "context/metrics";
import { selectMetricType } from "context/metrics/actions";
import { getThemeColor } from "utils/theme";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "13px 5px",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
  activeButton: ({ selectedMetric }) => {
    const val = {
      backgroundColor: getThemeColor(selectedMetric, theme),
      color: "#FFF",
    };
    return {
      ...val,
      "&:hover": val,
    };
  },
}));

export default function SelectMetricType() {
  const { metricsState, metricsDispatch, metricsUtils } = useMetricsContext();
  const classes = useStyles({
    selectedMetric: metricsState.metricType,
  });
  const veryNarrowView = useMediaQuery("(min-width:375px)");
  return (
    // Needs to be wrapped in Box to behave as a flex child.
    <Box>
      <ButtonGroup
        fullWidth
        orientation={`${veryNarrowView ? "horizontal" : "vertical"}`}
        style={{ boxShadow: "0 0 25px #3754AA1A" }}
        color="default"
        size="small"
        aria-label="Select Metric Type"
      >
        {metricsUtils.availableMetrics.map((metric) => (
          <Button
            key={metric}
            className={clsx(
              classes.button,
              metricsState.metricType === metric && classes.activeButton
            )}
            variant={metricsState.metricType === metric ? "contained" : "text"}
            onClick={() => {
              if (metricsState.metricType !== metric) {
                metricsDispatch(selectMetricType(metric));
              }
            }}
          >
            {metric}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
