import React from "react";
import { v4 as uuid } from "uuid";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import MUISelect from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

// const optionsExample = [
//   {
//     value: 'USD',
//     label: '$',
//   },
//   {
//     value: 'EUR',
//     label: '€',
//   },
//   {
//     value: 'BTC',
//     label: '฿',
//   },
//   {
//     value: 'JPY',
//     label: '¥',
//   },
// ];

const defaultOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const mapOptions = (option) => (
  <MenuItem key={option.label} value={option.value}>
    {option.label}
  </MenuItem>
);

const Select = React.forwardRef(function Select(props, ref) {
  const { options, label, value, onChange, ...rest } = props;
  const id = uuid();

  return (
    <FormControl variant="outlined" {...rest}>
      <InputLabel
        id={id}
        style={{
          backgroundColor: "#FFF",
          paddingLeft: "7px",
          paddingRight: "7px",
        }}
      >
        {label}
      </InputLabel>
      <MUISelect labelId={id} value={value} onChange={onChange} ref={ref}>
        {options ? options.map(mapOptions) : defaultOptions.map(mapOptions)}
      </MUISelect>
    </FormControl>
  );
});

export default Select;
