import React from "react";

import { Button, Box } from "@material-ui/core";

import {
  CampaignDatesBox,
  CampaignNamePageHeaderWithSubtitle,
} from "atoms/campaign";
import { useTrackerContext } from "context/roiTracker";

export default function Header() {
  const { trackerDispatch } = useTrackerContext();
  return (
    <Box display="flex" justifyContent="space-between" marginBottom={4}>
      <CampaignNamePageHeaderWithSubtitle subtitle="Sales Lift Tracker" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        width="50%"
      >
        <CampaignDatesBox />
        <Box display="flex">
          <Box mr={3}>
            <Button
              onClick={() => {
                trackerDispatch.setMainView("POST_CAMPAIGN_WIZARD");
              }}
              color="secondary"
              variant="contained"
              size="large"
            >
              Re-Upload Data
            </Button>
          </Box>
          <Button
            onClick={() => {
              trackerDispatch.setMainView("GENERAL_CAMPAIGN_DETAILS_PAGE");
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Campaign Details
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
