import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useTotalOrNoMeasurableLift } from "utils/formatters";

import PerformanceTableHead from "./PerformanceTableHead";

const useStyles = makeStyles({
  boldText: {
    fontWeight: 700,
  },
});

export default function ItemPerformanceTable({
  title,
  itemSet, //array of campaignIds in the order to display
  totals,
}) {
  const classes = useStyles();
  const totalOrNoMeasurableLift = useTotalOrNoMeasurableLift();
  return (
    <TableContainer>
      <Table aria-label={title} size="small">
        <PerformanceTableHead isTotalSummary={false} />
        <TableBody>
          {itemSet.map(({ upc, item_num, description, performance }) => (
            <TableRow key={upc}>
              <TableCell component="th" scope="row">
                {upc}
              </TableCell>
              <TableCell>{item_num}</TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>
                {description}
              </TableCell>
              <TableCell align="right">
                {totalOrNoMeasurableLift(performance.dollars.lift_percent, "%")}
              </TableCell>
              <TableCell align="right">
                {totalOrNoMeasurableLift(performance.dollars.lift_total, "$")}
              </TableCell>
              <TableCell align="right">
                {totalOrNoMeasurableLift(
                  performance.dollars.lift_per_loc_per_week,
                  "$"
                )}
              </TableCell>
              <TableCell align="right">
                {totalOrNoMeasurableLift(performance.units.lift_total)}
              </TableCell>
              <TableCell align="right">
                {totalOrNoMeasurableLift(
                  performance.units.lift_per_loc_per_week
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              className={classes.boldText}
              colSpan={3}
              component="th"
              scope="row"
              align="right"
            >
              SUBTOTAL:
            </TableCell>
            <TableCell align="right">
              {totalOrNoMeasurableLift(totals.dollars.lift_percent, "%")}
            </TableCell>
            <TableCell align="right">
              {totalOrNoMeasurableLift(totals.dollars.lift_total, "$")}
            </TableCell>
            <TableCell align="right">
              {totalOrNoMeasurableLift(
                totals.dollars.lift_per_loc_per_week,
                "$"
              )}
            </TableCell>
            <TableCell align="right">
              {totalOrNoMeasurableLift(totals.units.lift_total)}
            </TableCell>
            <TableCell align="right">
              {totalOrNoMeasurableLift(totals.units.lift_per_loc_per_week)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
