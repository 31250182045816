import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonGroup, Button, Box } from "@material-ui/core";

import { useCampaignsContext } from "context/campaigns";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    border: `1px ${theme.palette.text.secondary}`,
  },
  button: {
    fontWeight: "700 !important",
    minWidth: "50%",
    color: theme.palette.text.secondary,
    backgroundColor: "#FFF",
  },
  activeButton: {
    fontWeight: "700 !important",
    backgroundColor: theme.palette.text.secondary,
    color: "#fff",
    boxShadow: `inset 0 3px 6px #00000029`,
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
      color: "#fff",
    },
  },
}));

export default function NavLinks({ campaignId }) {
  const classes = useStyles();
  const { campaignsState } = useCampaignsContext();
  return (
    // Needs to be wrapped in Box to behave as a flex child.
    <Box>
      <ButtonGroup
        color="primary"
        size="small"
        aria-label="Select Media Line Item"
        className={classes.root}
        fullWidth
      >
        <Button
          color="default"
          component={NavLink}
          to={`/campaigns/${campaignId}/metrics`}
          className={classes.button}
          activeClassName={classes.activeButton}
        >
          METRICS
        </Button>
        {campaignsState.campaigns[campaignId].roi_tracker_enabled && (
          <Button
            color="default"
            component={NavLink}
            to={`/campaigns/${campaignId}/roi-tracker`}
            className={classes.button}
            activeClassName={classes.activeButton}
          >
            ROI
          </Button>
        )}
      </ButtonGroup>
    </Box>
  );
}
