import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useTrackerContext } from "context/roiTracker";
import { PreCampaignWizardProvider } from "context/preCampaignWizard";
import { PostCampaignWizardProvider } from "context/postCampaignWizard";

import PreCampaignLandingPage from "./PreCampaignLandingPage";
import PreCampaignSetupWizard from "./PreCampaignSetupWizard";
import MidCampaignLandingPage from "./MidCampaignLandingPage";
import PostCampaignSetupWizard from "./PostCampaignSetupWizard";
import PostCampaignViewROIResultsPage from "./PostCampaignViewROIResultsPage";
import GeneralCampaignDetailsPage from "./GeneralCampaignDetailsPage";

const useStyles = makeStyles((theme) => ({
  root: ({ trackerState }) => ({
    backgroundColor:
      trackerState.mainView === "GENERAL_CAMPAIGN_DETAILS_PAGE"
        ? theme.palette.background.paper
        : theme.palette.background.default,
    width: "100%",
    height: "100%",
    overflow: "auto",
  }),
}));

export default function ROITracker() {
  const { trackerState } = useTrackerContext();
  const classes = useStyles({ trackerState });
  return (
    <Box className={classes.root}>
      {trackerState.mainView === "PRE_CAMPAIGN_LANDING_PAGE" && (
        <PreCampaignLandingPage />
      )}
      {trackerState.mainView === "PRE_CAMPAIGN_WIZARD" && (
        <PreCampaignWizardProvider>
          <PreCampaignSetupWizard />
        </PreCampaignWizardProvider>
      )}
      {trackerState.mainView === "MID_CAMPAIGN_LANDING_PAGE" && (
        <MidCampaignLandingPage />
      )}
      {trackerState.mainView === "GENERAL_CAMPAIGN_DETAILS_PAGE" && (
        <GeneralCampaignDetailsPage />
      )}
      {trackerState.mainView === "POST_CAMPAIGN_WIZARD" && (
        <PostCampaignWizardProvider>
          <PostCampaignSetupWizard />
        </PostCampaignWizardProvider>
      )}
      {trackerState.mainView === "POST_CAMPAIGN_VIEW_ROI_RESULTS" && (
        <PostCampaignViewROIResultsPage />
      )}
    </Box>
  );
}
