import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useCampaignDetailsContext } from "context/campaignDetails";

export default function CampaignNamePageHeaderWithSubtitle({ subtitle }) {
  const { campaignDetailsState } = useCampaignDetailsContext();

  return (
    <Box>
      <Typography variant="h1">
        {campaignDetailsState.campaignDetails.name}
      </Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
    </Box>
  );
}
