import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { usePostCampaignWizardContext } from "context/postCampaignWizard";

import FullySetUpConfirmationPage from "./FullySetUpConfirmationPage";
import UploadSalesData from "./UploadSalesData";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.text.primary,
  },
}));

export default function PostCampaignSetupWizard() {
  const { wizardState } = usePostCampaignWizardContext();
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="100%"
    >
      {wizardState.step === "UPLOAD_SALES_DATA" && (
        // If adding more steps, see structure of Pre Campaign Wizard.
        <Box width={1000} mt={3}>
          <Typography component="h1" variant="h2">
            POST-CAMPAIGN SET-UP
          </Typography>
          <Typography
            className={classes.subTitle}
            component={Box}
            mt={2}
            variant="subtitle2"
            gutterBottom
          >
            Retrieve the retailer sales data that includes 2 weeks post campaign
            and Upload here. Our system will analyze the data and confirm
            matching stores, detect Out-of-stock and Merchandising anomalies,
            and allow you to view your results using multiple methodologies.
          </Typography>
          <UploadSalesData />
        </Box>
      )}
      {wizardState.step === "WIZARD_COMPLETE_CONFIRMATION" && (
        <FullySetUpConfirmationPage />
      )}
    </Box>
  );
}
