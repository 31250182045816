import clsx from "clsx";
import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";

import { Paper } from "atoms";
import { useCampaignsContext } from "context/campaigns";
import { useDashboardContext } from "context/dashboardLayout";
import { useDisplay } from "utils/theme";
import { fullDate } from "utils/formatters";

function campaignDate(date, display) {
  const month = display.widescreen ? "long" : "short";
  return fullDate(date, { month });
}

const useStyles = makeStyles((theme) => ({
  littleCircle: {
    minWidth: "16px",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: theme.palette.success.main,
  },
  activePaper: {
    backgroundColor: "#E6E9F4",
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: 7,
    boxShadow: "0 5px 15px #15223214",
  },
  button: {
    fontWeight: "700 !important",
    color: theme.palette.text.secondary,
    backgroundColor: "#FFF",
  },
}));

export default function CampaignBox() {
  const { dashboardDispatch } = useDashboardContext();
  const { campaignId } = useParams();
  const { campaignsState } = useCampaignsContext();
  const campaign = campaignsState.campaigns[campaignId];
  const classes = useStyles();
  const display = useDisplay();
  return (
    <Paper
      display="flex"
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
      minHeight="155px"
      p={2}
      my={2}
      className={clsx(classes.activePaper)}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h3">{campaign.name}</Typography>
          <Typography variant="subtitle2">
            {campaignDate(campaign.media_flight_start_at, display)} -{" "}
            {campaignDate(campaign.media_flight_end_at, display)}
          </Typography>
        </Box>
        <Box className={clsx(classes.littleCircle)} />
      </Box>
      <Box mt={2}>
        <Button
          fullWidth
          onClick={() => {
            dashboardDispatch.setDashboardRequestingNarrowSideNav(true);
          }}
          className={classes.button}
        >
          Exit Campaign
        </Button>
      </Box>
    </Paper>
  );
}
