import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Paper } from "atoms";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  bigNumber: ({ bigNumber }) => ({
    color: theme.palette.primary.main,
    fontSize: String(bigNumber).length < 11 ? "48px" : "30px",
  }),
  label: {
    fontSize: "18px",
  },
}));

export default function StackedPerformanceHighlight({ bigNumber, label }) {
  const classes = useStyles({ bigNumber });
  return (
    <Paper variant="outlined" p={2} width={306} className={classes.paper}>
      <Typography
        variant="h1"
        component="p"
        align="center"
        className={classes.bigNumber}
      >
        {bigNumber}
      </Typography>
      <Typography
        variant="h4"
        component="p"
        align="center"
        className={classes.label}
      >
        {label}
      </Typography>
    </Paper>
  );
}
