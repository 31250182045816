import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useTrackerContext } from "context/roiTracker";
import { Paper } from "atoms";
import { bullhorn, chart } from "img";

const useStyles = makeStyles({
  button: {
    width: 211,
  },
});

export default function PreCampaignLandingPage() {
  const { trackerDispatch } = useTrackerContext();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box
        maxWidth={632}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h1" align="center" gutterBottom>
            Welcome to the Sales Lift Tool!
          </Typography>
          <Typography variant="body2" align="center" gutterBottom>
            Using our self-serve analysis tool, you will be able to measure the
            impact of the media to at-store sales lift! Below is an overview of
            the process, but we will guide you through this easy process every
            step of the way.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={5} width="100%">
          <Paper
            variant="outlined"
            width={300}
            height={376}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              height={280}
              maxWidth={190}
            >
              <img
                src={bullhorn.src}
                aria-label={bullhorn.ariaLabel}
                width={121}
              />
              <Typography component="h2" variant="h3" align="center">
                PRE-CAMPAIGN
              </Typography>
              <Typography variant="subtitle2" align="center">
                Strategically establish a Test and Control store list plan to be
                used with your digital media program.
              </Typography>
            </Box>
          </Paper>
          <Paper
            variant="outlined"
            width={300}
            height={376}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              height={280}
              maxWidth={190}
            >
              <img src={chart.src} aria-label={chart.ariaLabel} width={121} />
              <Typography component="h2" variant="h3" align="center">
                POST-CAMPAIGN
              </Typography>
              <Typography variant="subtitle2" align="center">
                After the campaign leverage our self-serve tool to dig into
                Sales lift results.
              </Typography>
            </Box>
          </Paper>
        </Box>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            trackerDispatch.setMainView("PRE_CAMPAIGN_WIZARD");
          }}
        >
          Let's Begin!
        </Button>
      </Box>
    </Box>
  );
}
