import { login as apiCall } from "utils/api/auth";
import { toast } from "react-toastify";

export default async function login(username, password, authDispatch) {
  const [response, error] = await apiCall(username, password);
  if (!error) {
    authDispatch({
      type: "SET_USER",
      payload: response.data,
    });
    toast.success(`Logged in as ${response.data.username}`);
  } else {
    toast.error("Login failed. Please try again.");
  }
}
