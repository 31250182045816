import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import { useDisplay } from "utils/theme";
import { useCampaignsContext } from "context/campaigns";

import TabPanel from "./TabPanel";
export { TabPanel };

const CATEGORIES = ["active", "upcoming", "completed"];

function a11yProps(category) {
  return {
    id: `simple-tab-${category}`,
    "aria-controls": `simple-tabpanel-${category}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tab: ({ display }) => ({
    textTransform: "uppercase",
    fontSize: display.widescreen ? "16px" : "12px",
    fontWeight: 700,
    width: "33%",
    backgroundColor: display.desktop
      ? undefined
      : theme.palette.background.paper,
    border: display.desktop
      ? undefined
      : `1px solid ${theme.palette.text.secondary}`,
    boxShadow: display.desktop ? undefined : "1px -10px 20px #29344A80",
  }),
  selectedTab: ({ display }) => ({
    backgroundColor: display.desktop ? undefined : theme.palette.primary.main,
    color: display.desktop ? undefined : theme.palette.primary.contrastText,
  }),
  indicator: ({ display }) => ({
    display: display.desktop ? "block" : "none",
  }),
  tabLabel: {
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
}));

export default function SelectCampaignsCategory({
  selectedCategory,
  setCategory,
}) {
  const display = useDisplay();
  const classes = useStyles({ display });
  const { campaignsState } = useCampaignsContext();

  const handleChange = (_, newValue) => {
    setCategory(CATEGORIES[newValue]);
  };

  return (
    <Box m={display.desktop ? 1 : 0}>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        value={CATEGORIES.indexOf(selectedCategory)}
        onChange={handleChange}
        aria-label="Select Campaign Category"
        classes={{
          indicator: classes.indicator,
        }}
      >
        {CATEGORIES.map((c) => (
          <Tab
            key={c}
            style={{ minHeight: "63px" }}
            label={
              <span
                className={classes.tabLabel}
              >{`${c} ${campaignsState[c].length}`}</span>
            }
            {...a11yProps(c)}
            className={classes.tab}
            classes={{
              selected: classes.selectedTab,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
