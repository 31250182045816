import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TodayIcon from "@material-ui/icons/Today";

import { Paper } from "atoms";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { parseDate } from "utils/api";
import { fullDate } from "utils/formatters";

const useStyles = makeStyles((theme) => ({
  textAndIcon: {
    color: theme.palette.text.secondary,
  },
}));

export default function CampaignDates() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const { campaignDetails } = campaignDetailsState;
  const classes = useStyles();

  return (
    <Paper
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      // seem to have to specify all 3 paddings
      p={0}
      px={2}
      py={0.75}
      color="secondary"
      variant="outlined"
      borderRadius={4}
      mb={1}
      height={34}
    >
      <TodayIcon className={classes.textAndIcon} />
      <Typography
        className={classes.textAndIcon}
        component={Box}
        variant="h5"
        ml={2}
      >
        {fullDate(parseDate(campaignDetails.media_flight_start_at))} -{" "}
        {fullDate(parseDate(campaignDetails.media_flight_end_at))}
      </Typography>
    </Paper>
  );
}
