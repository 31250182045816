import React from "react";

import { KeyboardDatePicker } from "@material-ui/pickers";

export default function DatePicker({ label, ...props }) {
  return (
    <KeyboardDatePicker
      disableToolbar
      inputVariant="outlined"
      format="MM/dd/yyyy"
      margin="normal"
      label={label}
      KeyboardButtonProps={{
        "aria-label": label,
      }}
      {...props}
    />
  );
}
