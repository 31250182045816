import React, { useContext } from "react";
import { toast } from "react-toastify";

import { useTrackerContext } from "context/roiTracker";
import { useCampaignDetailsContext } from "context/campaignDetails";

const STARTING_POINTS_BASED_ON_ROI_STATE = {
  CREATED: "CONFIRM_CAMPAIGN_DETAILS",
  CONFIRMED: "UPLOAD_SALES_DATA",
  PRE_BAD_DATA: "UPLOAD_SALES_DATA",
  STORE_LIST_GEN_FAILED: "UPLOAD_SALES_DATA",
  STORE_LIST_GENERATED: "CONFIRM_LOCATION_LIST",
};

const STEPS_WITH_LABELS = new Map([
  ["CONFIRM_CAMPAIGN_DETAILS", "Campaign Details"],
  ["UPLOAD_SALES_DATA", "Upload Sales Data"],
  ["CONFIRM_LOCATION_LIST", "Confirm Store List Plan"],
  ["WIZARD_COMPLETE_CONFIRMATION", ""],
]);

const STEPS = Array.from(STEPS_WITH_LABELS.keys());

const WizardContext = React.createContext();

function wizardEntryStep(campaignDetails) {
  return (
    STARTING_POINTS_BASED_ON_ROI_STATE[campaignDetails.roi_state] ||
    "CONFIRM_CAMPAIGN_DETAILS"
  );
}

export function PreCampaignWizardProvider({ children }) {
  const { campaignDetailsState } = useCampaignDetailsContext();

  const [step, setStep] = React.useState(
    wizardEntryStep(campaignDetailsState.campaignDetails)
  );

  return (
    <WizardContext.Provider
      value={{
        wizardState: {
          step,
        },
        wizardDispatch: {
          setStep,
        },
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}

export function usePreCampaignWizardContext() {
  const context = useContext(WizardContext);
  if (context === undefined) {
    throw new Error(
      "usePreCampaignWizardContext must be used within a PreCampaignWizardProvider"
    );
  }
  const { wizardState, wizardDispatch } = context;
  const { trackerDispatch } = useTrackerContext();

  const visibleSteps = STEPS.filter(
    (el) => el !== "WIZARD_COMPLETE_CONFIRMATION"
  );

  const activeStepIndex = visibleSteps.indexOf(wizardState.step);

  function finishWizard() {
    trackerDispatch.setMainView("MID_CAMPAIGN_LANDING_PAGE");
  }

  function nextStep() {
    const target = STEPS.indexOf(wizardState.step) + 1;
    if (STEPS[target] !== undefined) {
      wizardDispatch.setStep(STEPS[target]);
    }
  }

  function prevStep() {
    const target = STEPS.indexOf(wizardState.step) - 1;
    if (STEPS[target] !== undefined) {
      wizardDispatch.setStep(STEPS[target]);
    }
  }
  async function asyncCallbackThenStep(callback, targetStep) {
    trackerDispatch.setLoading(true);
    try {
      await callback();
      if (targetStep) {
        wizardDispatch.setStep(targetStep);
      } else {
        nextStep();
      }
    } catch (e) {
      toast.error(e.message);
    }
    trackerDispatch.setLoading(false);
  }
  return {
    ...context,
    wizardUtils: {
      finishWizard,
      nextStep,
      prevStep,
      asyncCallbackThenStep,
      activeStepIndex,
      stepsWithLabels: STEPS_WITH_LABELS,
    },
    stepperSteps: visibleSteps,
  };
}
