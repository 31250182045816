import React, { useContext } from "react";
import { toast } from "react-toastify";

import { useTrackerContext } from "context/roiTracker";

const STEPS = ["UPLOAD_SALES_DATA", "WIZARD_COMPLETE_CONFIRMATION"];

const WizardContext = React.createContext();

export function PostCampaignWizardProvider({ children }) {
  const [step, setStep] = React.useState(STEPS[0]);

  return (
    <WizardContext.Provider
      value={{
        wizardState: {
          step,
        },
        wizardDispatch: {
          setStep,
        },
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}

export function usePostCampaignWizardContext() {
  const context = useContext(WizardContext);
  if (context === undefined) {
    throw new Error(
      "usePostCampaignWizardContext must be used within a PostCampaignWizardProvider"
    );
  }
  const { wizardState, wizardDispatch } = context;
  const { trackerDispatch } = useTrackerContext();

  function finishWizard() {
    trackerDispatch.setMainView("POST_CAMPAIGN_VIEW_ROI_RESULTS");
  }

  function nextStep() {
    const target = STEPS.indexOf(wizardState.step) + 1;
    if (STEPS[target] !== undefined) {
      wizardDispatch.setStep(STEPS[target]);
    }
  }

  function prevStep() {
    const target = STEPS.indexOf(wizardState.step) - 1;
    if (STEPS[target] !== undefined) {
      wizardDispatch.setStep(STEPS[target]);
    }
  }
  async function asyncCallbackThenStep(callback) {
    trackerDispatch.setLoading(true);
    try {
      await callback();
      nextStep();
    } catch (e) {
      toast.error(e.message);
    }
    trackerDispatch.setLoading(false);
  }
  return {
    ...context,
    wizardUtils: {
      finishWizard,
      nextStep,
      prevStep,
      asyncCallbackThenStep,
    },
  };
}
