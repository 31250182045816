import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { makeStyles } from "@material-ui/core/styles";

import LabeledDataPair from "./LabeledDataPair";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `${theme.palette.text.secondary} solid 1px`,
    borderRadius: 8,
  },
  titleTextOverlapWrapper: {
    backgroundColor: "#FFF",
  },
  titleText: {
    color: theme.palette.text.secondary,
  },
  list: {
    // Takes padding from the parent
    paddingLeft: 0,
    width: "100%",
    whiteSpace: "nowrap",
  },
  comparisonConfidence: {
    color: theme.palette.primary.main,
    fontSize: "16px",
  },
}));

export default function StoreListPlanAnalysis() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const { campaignDetails, salesData } = campaignDetailsState;
  const classes = useStyles();
  return (
    <Box
      position="relative"
      className={classes.root}
      width={537}
      mt={3}
      py={2}
      px={2}
    >
      <Box
        position="absolute"
        width={250}
        top={-9}
        left={537 / 4}
        className={classes.titleTextOverlapWrapper}
      >
        <Typography align="center" className={classes.titleText} variant="h3">
          Store List Plan Analysis
        </Typography>
      </Box>
      <Box mt={4} />
      <ul className={classes.list}>
        <Box display="flex" width="100%" justifyContent="space-between" mb={3}>
          <Box width="60%">
            <LabeledDataPair
              name="Pre-Period Analysis Start"
              value={campaignDetails.diff_in_diff_start_at}
            />
            <LabeledDataPair
              name="Number of Control Stores"
              value={campaignDetails.num_control_locs}
            />
            <LabeledDataPair
              name="Number of Test Stores"
              value={campaignDetails.num_test_locs}
            />
          </Box>
          <Box width="50%">
            <LabeledDataPair
              name="Pre-Period Analysis End"
              value={campaignDetails.diff_in_diff_end_at}
            />
            <LabeledDataPair
              name="Control Stores Units/Store/Week"
              value={salesData.units_per_loc_per_week_control}
            />
            <LabeledDataPair
              name="Test Stores Units/Store/Week"
              value={salesData.units_per_loc_per_week_test}
            />
          </Box>
        </Box>
        <Typography
          align="center"
          className={classes.comparisonConfidence}
          variant="h2"
          component="p"
        >
          COMPARISON CONFIDENCE: {campaignDetails.ai_comparison_confidence}%
        </Typography>
      </ul>
    </Box>
  );
}
