/*
  these were created by modifying the CodeSandbox for https://www.npmjs.com/package/javascript-color-gradient
  Like this:

  colorGradient.setGradient("#FFFFFF", "#5142C3");
  colorGradient.setMidpoint(100);
  const colorArr = colorGradient.getArray();
  console.log(colorArr)
*/

export const impressions = [
  "#fdfdfe",
  "#fcfbfe",
  "#faf9fd",
  "#f8f7fd",
  "#f6f6fc",
  "#f5f4fb",
  "#f3f2fb",
  "#f1f0fa",
  "#efeefa",
  "#eeecf9",
  "#eceaf8",
  "#eae8f8",
  "#e8e6f7",
  "#e7e5f7",
  "#e5e3f6",
  "#e3e1f5",
  "#e1dff5",
  "#e0ddf4",
  "#dedbf4",
  "#dcd9f3",
  "#dad7f2",
  "#d9d5f2",
  "#d7d4f1",
  "#d5d2f1",
  "#d4d0f0",
  "#d2ceef",
  "#d0ccef",
  "#cecaee",
  "#cdc8ee",
  "#cbc6ed",
  "#c9c4ec",
  "#c7c3ec",
  "#c6c1eb",
  "#c4bfeb",
  "#c2bdea",
  "#c0bbe9",
  "#bfb9e9",
  "#bdb7e8",
  "#bbb5e8",
  "#b9b3e7",
  "#b8b2e6",
  "#b6b0e6",
  "#b4aee5",
  "#b2ace5",
  "#b1aae4",
  "#afa8e3",
  "#ada6e3",
  "#aba4e2",
  "#aaa2e2",
  "#a8a1e1",
  "#a69fe0",
  "#a59de0",
  "#a39bdf",
  "#a199df",
  "#9f97de",
  "#9e95dd",
  "#9c93dd",
  "#9a91dc",
  "#988fdc",
  "#978edb",
  "#958cda",
  "#938ada",
  "#9188d9",
  "#9086d9",
  "#8e84d8",
  "#8c82d7",
  "#8a80d7",
  "#897ed6",
  "#877dd6",
  "#857bd5",
  "#8379d4",
  "#8277d4",
  "#8075d3",
  "#7e73d3",
  "#7d71d2",
  "#7b6fd1",
  "#796dd1",
  "#776cd0",
  "#766ad0",
  "#7468cf",
  "#7266ce",
  "#7064ce",
  "#6f62cd",
  "#6d60cd",
  "#6b5ecc",
  "#695ccb",
  "#685bcb",
  "#6659ca",
  "#6457ca",
  "#6255c9",
  "#6153c8",
  "#5f51c8",
  "#5d4fc7",
  "#5b4dc7",
  "#5a4bc6",
  "#584ac5",
  "#5648c5",
  "#5446c4",
  "#5344c4",
  "#5142c3",
];

export const activations = [
  "#fffdfe",
  "#fffbfc",
  "#fff9fb",
  "#fff6fa",
  "#fef4f9",
  "#fef2f7",
  "#fef0f6",
  "#feeef5",
  "#feecf3",
  "#feeaf2",
  "#fee8f1",
  "#fee5f0",
  "#fee3ee",
  "#fde1ed",
  "#fddfec",
  "#fdddeb",
  "#fddbe9",
  "#fdd9e8",
  "#fdd7e7",
  "#fdd4e5",
  "#fdd2e4",
  "#fdd0e3",
  "#fccee2",
  "#fccce0",
  "#fccadf",
  "#fcc8de",
  "#fcc5dc",
  "#fcc3db",
  "#fcc1da",
  "#fcbfd9",
  "#fcbdd7",
  "#fbbbd6",
  "#fbb9d5",
  "#fbb7d3",
  "#fbb4d2",
  "#fbb2d1",
  "#fbb0d0",
  "#fbaece",
  "#fbaccd",
  "#fbaacc",
  "#faa8cb",
  "#faa6c9",
  "#faa3c8",
  "#faa1c7",
  "#fa9fc5",
  "#fa9dc4",
  "#fa9bc3",
  "#fa99c2",
  "#fa97c0",
  "#fa95bf",
  "#f992be",
  "#f990bc",
  "#f98ebb",
  "#f98cba",
  "#f98ab9",
  "#f988b7",
  "#f986b6",
  "#f983b5",
  "#f981b3",
  "#f87fb2",
  "#f87db1",
  "#f87bb0",
  "#f879ae",
  "#f877ad",
  "#f875ac",
  "#f872ab",
  "#f870a9",
  "#f86ea8",
  "#f76ca7",
  "#f76aa5",
  "#f768a4",
  "#f766a3",
  "#f764a2",
  "#f761a0",
  "#f75f9f",
  "#f75d9e",
  "#f75b9c",
  "#f6599b",
  "#f6579a",
  "#f65599",
  "#f65297",
  "#f65096",
  "#f64e95",
  "#f64c93",
  "#f64a92",
  "#f64891",
  "#f54690",
  "#f5448e",
  "#f5418d",
  "#f53f8c",
  "#f53d8b",
  "#f53b89",
  "#f53988",
  "#f53787",
  "#f53585",
  "#f43384",
  "#f43083",
  "#f42e82",
  "#f42c80",
  "#f42a7f",
];

export const clicks = [
  "#fdfeff",
  "#fbfeff",
  "#f9fdff",
  "#f7fdfe",
  "#f5fcfe",
  "#f3fcfe",
  "#f1fbfe",
  "#effafe",
  "#edfafe",
  "#ebf9fe",
  "#e9f9fe",
  "#e7f8fd",
  "#e5f8fd",
  "#e3f7fd",
  "#e1f6fd",
  "#dff6fd",
  "#ddf5fd",
  "#dbf5fd",
  "#d9f4fd",
  "#d7f4fc",
  "#d5f3fc",
  "#d3f2fc",
  "#d1f2fc",
  "#cff1fc",
  "#cdf1fc",
  "#caf0fc",
  "#c8f0fb",
  "#c6effb",
  "#c4eefb",
  "#c2eefb",
  "#c0edfb",
  "#beedfb",
  "#bcecfb",
  "#baecfb",
  "#b8ebfa",
  "#b6eafa",
  "#b4eafa",
  "#b2e9fa",
  "#b0e9fa",
  "#aee8fa",
  "#ace8fa",
  "#aae7fa",
  "#a8e6f9",
  "#a6e6f9",
  "#a4e5f9",
  "#a2e5f9",
  "#a0e4f9",
  "#9ee4f9",
  "#9ce3f9",
  "#9ae3f9",
  "#98e2f8",
  "#96e1f8",
  "#94e1f8",
  "#92e0f8",
  "#90e0f8",
  "#8edff8",
  "#8cdff8",
  "#8adef7",
  "#88ddf7",
  "#86ddf7",
  "#84dcf7",
  "#82dcf7",
  "#80dbf7",
  "#7edbf7",
  "#7cdaf7",
  "#7ad9f6",
  "#78d9f6",
  "#76d8f6",
  "#74d8f6",
  "#72d7f6",
  "#70d7f6",
  "#6ed6f6",
  "#6cd5f6",
  "#6ad5f5",
  "#68d4f5",
  "#65d4f5",
  "#63d3f5",
  "#61d3f5",
  "#5fd2f5",
  "#5dd1f5",
  "#5bd1f4",
  "#59d0f4",
  "#57d0f4",
  "#55cff4",
  "#53cff4",
  "#51cef4",
  "#4fcdf4",
  "#4dcdf4",
  "#4bccf3",
  "#49ccf3",
  "#47cbf3",
  "#45cbf3",
  "#43caf3",
  "#41c9f3",
  "#3fc9f3",
  "#3dc8f3",
  "#3bc8f2",
  "#39c7f2",
  "#37c7f2",
  "#35c6f2",
];
