import { getCampaignDetails as apiCall } from "utils/api/campaigns";

export default function getCampaignDetails(campaignId, params = {}) {
  return async function getCampaignDetailsAction(campaignDetailsDispatch) {
    const { pre_period, ...queryParams } = params;
    // pre_period is either "diff_in_diff" or a number of weeks prior to campaign start.
    if (pre_period === "diff_in_diff") {
      queryParams.diff_in_diff_comparison = true;
    } else if (pre_period !== undefined) {
      queryParams.diff_in_diff_comparison = false;
      queryParams.weeks_prior_comparison = pre_period;
    }

    const [response, error] = await apiCall(campaignId, queryParams);
    if (!error) {
      campaignDetailsDispatch({
        type: "SET_CAMPAIGN",
        payload: response.data,
      });
    } else {
      throw error;
    }
  };
}
