import { getCampaignStatus } from "utils";

export const initialState = {
  campaignDetails: {
    // backend.campaigns.serializers/CampaignDetails
  },
  salesData: {
    // backend.campaigns.serializers/SalesData
  },
  roiResults: {
    // backend.campaigns.serializers/ROIResults
  },
  status: "", // ACTIVE, UPCOMING, or COMPLETE
  loading: true,
  error: null,
};

export function reducer(currentState, action) {
  switch (action.type) {
    case "SET_CAMPAIGN": {
      const { sales_data, roi_results, ...campaignDetails } = action.payload;
      if (roi_results) {
        roi_results.feature_items = roi_results.item_set.filter(
          (item) => item.relevance === "FEATURE"
        );
        roi_results.halo_items = roi_results.item_set.filter(
          (item) => item.relevance === "HALO"
        );
      }
      const status = getCampaignStatus(
        campaignDetails.media_flight_start_at,
        campaignDetails.media_flight_end_at
      );
      return {
        ...currentState,
        campaignDetails,
        salesData: sales_data,
        roiResults: roi_results,
        status,
      };
    }
    case "SET_LOADING": {
      return {
        ...currentState,
        loading: action.payload,
      };
    }
    case "SET_ERROR": {
      return {
        ...currentState,
        error: action.payload,
      };
    }
    default:
      throw new Error(
        `CampaignDetailsProvider dispatch received unhandled dispatch action type: ${action.type}`
      );
  }
}
