import React from "react";
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { Paper } from "atoms";
import { useMetricsContext } from "context/metrics";
import { dashboardTheme } from "theme";
import { titleCase } from "utils/formatters";
import { useDisplay } from "utils/theme";
import { getThemeColor } from "utils/theme";

import {
  AgeChart,
  GenderChart,
  GeographyChart,
  MetricOverTimeChart,
} from "./charts";
import Header from "./Header";
import METRIC_TOTALS_MAP from "./metricsTotalsMap";

highchartsMap(Highcharts);

const useStyles = makeStyles((theme) => ({
  mobileMetricTotalNumber: ({ metricType }) => ({
    color: getThemeColor(metricType, theme),
    fontSize: "48px",
  }),
  mobileMetricTotalLabel: {
    fontSize: "18px",
  },
}));

export default function Metrics() {
  const { metricsState } = useMetricsContext();
  const { metricType } = metricsState;
  const display = useDisplay();
  const classes = useStyles({ metricType });

  // Note that overflow: hidden and flexBasis: 0 on wrappers make Highchart charts properly responsive
  return (
    <Box p={display.widescreen ? 5 : 2}>
      {/* Header */}
      <Header />
      {/* Metric Details */}
      <Box mt={1}>
        <Grid container spacing={2}>
          {/* Over Time */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {display.tablet ? (
                <Grid item xs={12}>
                  <MetricOverTimeChart
                    color={dashboardTheme.palette[metricsState.metricType]}
                    data={metricsState.metricDetails.overTime}
                    metric={metricsState.metricType}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <Paper p={2}>
                    <Typography
                      variant="h1"
                      component="p"
                      align="center"
                      className={classes.mobileMetricTotalNumber}
                    >
                      {Number(
                        metricsState.metricDetails.totals[
                          METRIC_TOTALS_MAP.get(metricType)
                        ]
                      ).toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h4"
                      component="p"
                      align="center"
                      className={classes.mobileMetricTotalLabel}
                    >
                      Total {titleCase(metricType)}
                    </Typography>
                  </Paper>
                </Grid>
              )}
              {/* By Gender */}
              <Grid item xs={12} md={6}>
                <GenderChart
                  metric={metricsState.metricType}
                  data={metricsState.metricDetails.gender}
                  color={dashboardTheme.palette[metricsState.metricType]}
                  genderOptions={metricsState.metricDetails.gender.map(
                    ([range]) => range
                  )}
                />
              </Grid>
              {/* By Age */}
              <Grid item xs={12} md={6}>
                <AgeChart
                  metric={metricsState.metricType}
                  data={metricsState.metricDetails.ageGroup}
                  color={dashboardTheme.palette[metricsState.metricType]}
                  ageRanges={metricsState.metricDetails.ageGroup.map(
                    ([range]) => range
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* By Geography */}
          <Grid item xs={12} md={4}>
            <GeographyChart
              metric={metricsState.metricType}
              data={metricsState.metricDetails.geography}
              color={dashboardTheme.palette[metricsState.metricType]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
