import React from "react";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { userDecidedToOverride } from "context/campaignDetails/actions";
import { usePreCampaignWizardContext } from "context/preCampaignWizard";
import { ConfirmModal } from "atoms";

export default function OverrideModal({ open, setOpen }) {
  const { wizardUtils } = usePreCampaignWizardContext();
  const { campaignDetailsDispatch } = useCampaignDetailsContext();
  function onConfirm() {
    wizardUtils.asyncCallbackThenStep(
      async () => await campaignDetailsDispatch(userDecidedToOverride)
    );
  }
  return (
    <ConfirmModal
      onConfirm={onConfirm}
      ariaLabel="Confirm Override Store List"
      open={open}
      setOpen={setOpen}
      text="Have your own Test/Control stores selected that you would prefer to use? Click Continue to override the machine learning test control store list plan."
    />
  );
}
