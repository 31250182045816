import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { CampaignDetailsList, StoreListPlanAnalysis } from "atoms/campaign";

const useStyles = makeStyles({
  captionText: {
    fontSize: "14px",
  },
});

export default function TestControlDetails() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const classes = useStyles();
  return (
    <Box width={863} ml={4}>
      <Typography variant="body2" className={classes.captionText}>
        Based on the Sales Data uploaded and key campaign details, our system
        selected the optimal pre-period and most comparable Test and Control
        Stores to analyze sales results against. By Saving, our system will
        retain this information for Post-Campaign analysis.
      </Typography>
      <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
        <CampaignDetailsList />
        {campaignDetailsState.salesData && <StoreListPlanAnalysis />}
      </Box>
    </Box>
  );
}
