import { formatDate } from "utils/api";
import { putCampaign } from "utils/api/campaigns";

export default function confirmCampaignDetails({
  budget,
  channelType,
  desiredLocationCount,
  featureUpcs,
  haloUpcs,
  mediaFlightEnd,
  mediaFlightStart,
  name,
  newItem,
  otherTacticsExecuted,
  proxyUpcs,
  merchandising,
  retailer,
  campaignDetailsConfirmed,
  locListPlanSaved,
}) {
  return async function confirmCampaignDetailsAction(
    campaignDetailsDispatch,
    campaignDetailsState
  ) {
    const [response, error] = await putCampaign(
      campaignDetailsState.campaignDetails.id,
      {
        name,
        budget,
        merchandising,
        retailer,
        feature_upcs: featureUpcs.filter((el) => el !== ""),
        halo_upcs: haloUpcs.filter((el) => el !== ""),
        proxy_upcs:
          proxyUpcs && (newItem ? proxyUpcs.filter((el) => el !== "") : []),
        channel_type: channelType,
        desired_loc_count: desiredLocationCount,
        is_new_item: newItem,
        media_flight_end_at: mediaFlightEnd && formatDate(mediaFlightEnd),
        media_flight_start_at: mediaFlightStart && formatDate(mediaFlightStart),
        other_tactics_executed: otherTacticsExecuted,
        campaign_details_confirmed: campaignDetailsConfirmed,
        loc_list_plan_saved: locListPlanSaved,
      }
    );

    if (error) {
      throw new Error(error);
    } else {
      campaignDetailsDispatch({
        type: "SET_CAMPAIGN",
        payload: response.data,
      });
    }
  };
}
