import React from "react";
import { useHistory } from "react-router-dom";

import CampaignsList from "CampaignsList";

import { useCampaignsContext } from "context/campaigns";
import { useDisplay } from "utils/theme";
import { useAuthContext } from "context/auth";

export default function HomePage() {
  const display = useDisplay();
  const { campaignsState } = useCampaignsContext();
  const { authState } = useAuthContext();
  const history = useHistory();

  React.useEffect(() => {
    // Redirect to the first campaign with a media line item.
    if (display.desktop && Object.keys(campaignsState.campaigns).length > 0) {
      let target;
      const allCampaigns = [
        ...campaignsState.active,
        ...campaignsState.completed,
        ...campaignsState.upcoming,
      ];
      for (let campaign of allCampaigns) {
        if (campaignsState.campaigns[campaign].medialineitem_set.length > 0) {
          target = campaign;
          break;
        }
      }

      if (target && authState.user) {
        history.replace(`/campaigns/${target}/metrics`);
      }
    }
  }, [display, campaignsState, history, authState]);

  return <>{!display.desktop && <CampaignsList />}</>;
}
