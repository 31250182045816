import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { makeStyles } from "@material-ui/core/styles";

import { ConfirmModal, Paper } from "atoms";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { confirmCampaignDetails } from "context/campaignDetails/actions";
import { usePreCampaignWizardContext } from "context/preCampaignWizard";
import { inputsReducer } from "utils";
import { parseDate } from "utils/api";
import ProgramDetails from "./ProgramDetails";
import ProductDetails from "./ProductDetails";

const TOOLTIPS = {
  name: "It is not recommended to change this field since it matches the media name.",
  budget: "Media budget you are measuring against.",
  desiredLocationCount:
    "How many total store locations do you want to split the media across and measure results against? This will help the system to select a proper TEST and CONTROL list. If only running media across these stores, a $25-35 per store per week media saturation is recommended for optimal sales lift.",
  retailer: "Please select the 1 retailer that you will upload data from.",
  featureUpcs:
    "These should be UPC’s that are key items featured in the creative work with an opportunity to purchase.",
  haloUpcs:
    "These are items that may be differing sizes/counts/flavor variants but are a realistic substitute for the featured UPC item.",
  proxyUpcs:
    "If measuring a New Item, but you have data on a ‘like’ UPC (meaning you predict it would have similar sales trends because it is a flavor variant or a rolling UPC change), entering that Proxy UPC will allow the system to designate a Control store list.",
  newItem:
    'Select "New" if the item has less than 3 months of data across the specific media coverage store list.',
  otherTacticsExecuted:
    "This includes other media on or off the retailer site, in-store signage or demos, etc.",
};

const getInitialState = ({
  name,
  is_new_item,
  proxy_upcs,
  channel_type,
  feature_upcs,
  halo_upcs,
  media_flight_start_at,
  media_flight_end_at,
  budget,
  desired_loc_count,
  retailer,
  other_tactics_executed,
  merchandising,
}) => ({
  name,
  newItem: is_new_item,
  proxyUpcs: proxy_upcs,
  channelType: channel_type,
  featureUpcs: feature_upcs,
  haloUpcs: halo_upcs,
  mediaFlightStart: parseDate(media_flight_start_at),
  mediaFlightEnd: parseDate(media_flight_end_at),
  budget,
  desiredLocationCount: desired_loc_count,
  retailer,
  otherTacticsExecuted: other_tactics_executed,
  merchandising,
});

const useStyles = makeStyles((theme) => ({
  tooltipPaper: {
    backgroundColor: theme.palette.background.default,
  },
  tooltipIcon: ({ subpage }) => ({
    marginLeft: subpage === 0 ? 73 : 47,
    marginRight: 25,
  }),
  tooltipText: ({ subpage }) => ({
    marginRight: subpage === 0 ? 73 : 47,
  }),
  outlinedButtonOnGray: {
    backgroundColor: "#FFF",
  },
}));

export default function ConfirmCampaignDetailsForm() {
  const { campaignDetailsState, campaignDetailsDispatch } =
    useCampaignDetailsContext();
  const { wizardUtils } = usePreCampaignWizardContext();

  const [inputsState, inputsDispatch] = React.useReducer(
    inputsReducer,
    getInitialState(campaignDetailsState.campaignDetails)
  );
  const [subpage, setSubpage] = React.useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  // String representation of one of the inputsState keys.
  const [selectedTooltip, setSelectedTooltip] = React.useState(null);
  const classes = useStyles({ subpage });

  const aboutToDeleteProgress =
    campaignDetailsState.campaignDetails.roi_state !== "CREATED" &&
    subpage === 1;

  function handleChange(target, payload) {
    inputsDispatch({
      target,
      payload,
    });
  }

  function handleTooltipClick(target) {
    if (target === selectedTooltip) {
      setSelectedTooltip(null);
    } else {
      setSelectedTooltip(target);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (subpage === 0) {
      setSubpage(1);
    } else {
      if (aboutToDeleteProgress) {
        setConfirmModalOpen(true);
      } else {
        wizardUtils.asyncCallbackThenStep(
          async () =>
            await campaignDetailsDispatch(confirmCampaignDetails(inputsState))
        );
      }
    }
  }

  React.useEffect(() => {
    setSelectedTooltip(null);
  }, [subpage]);

  return (
    <>
      <ConfirmModal
        onConfirm={() => {
          wizardUtils.asyncCallbackThenStep(
            async () =>
              await campaignDetailsDispatch(confirmCampaignDetails(inputsState))
          );
        }}
        ariaLabel="Confirm Store List?"
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        text="Are you sure? This will delete your progress and start over."
      />
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Paper
            variant="outlined"
            p={5}
            display="flex"
            flexDirection="column"
            mb={3}
          >
            <Typography variant="h3" gutterBottom>
              {subpage === 0 ? "1. Program Details" : "2. Product Details"}
            </Typography>
            {subpage === 0 ? (
              <ProgramDetails
                handleChange={handleChange}
                inputsState={inputsState}
                handleTooltipClick={handleTooltipClick}
              />
            ) : (
              <ProductDetails
                handleChange={handleChange}
                inputsState={inputsState}
                handleTooltipClick={handleTooltipClick}
              />
            )}
            {selectedTooltip !== null && (
              <Paper
                className={classes.tooltipPaper}
                width={750}
                height={94}
                alignSelf="center"
                display="flex"
                alignItems="center"
                py="20px"
              >
                <Help className={classes.tooltipIcon} />
                <Typography variant="caption" className={classes.tooltipText}>
                  {TOOLTIPS[selectedTooltip]}
                </Typography>
              </Paper>
            )}
          </Paper>
          <Box display="flex" width="100%" justifyContent="space-between">
            {subpage === 1 ? (
              <Button
                size="large"
                onClick={() => setSubpage(0)}
                startIcon={<ArrowBackIosOutlinedIcon />}
              >
                PREVIOUS
              </Button>
            ) : (
              // This Box just helps with spacing when the Button is not around.
              <Box />
            )}
            <Box display="flex">
              {campaignDetailsState.campaignDetails.roi_state !== "CREATED" && (
                <Box mr={3}>
                  <Button
                    onClick={wizardUtils.nextStep}
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.outlinedButtonOnGray}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                {aboutToDeleteProgress ? "Update Campaign Details" : "Continue"}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}
