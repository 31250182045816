import React from "react";
import { useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";

import { useCampaignsContext } from "context/campaigns";
import { useDisplay } from "utils/theme";

import CampaignBox from "./CampaignBox";

export default function CampaignsCategory({ variant, campaignIds }) {
  const { campaignId } = useParams();
  const { campaignsState } = useCampaignsContext();
  const display = useDisplay();
  return (
    <Box p={display.desktop ? 0 : 2}>
      {campaignIds.map((id) => {
        const campaign = campaignsState.campaigns[id];
        return (
          <CampaignBox
            key={String(id)}
            id={id}
            campaign={campaign}
            activeCampaignId={campaignId}
            variant={variant}
          />
        );
      })}
    </Box>
  );
}
