import React from "react";

export default function TabPanel({
  children,
  selectedCategory,
  category,
  ...rest
}) {
  // https://v4.mui.com/components/tabs/#simple-tabs
  // https://v4.mui.com/components/tabs/#accessibility
  return (
    <div
      role="tabpanel"
      hidden={category !== selectedCategory}
      id={`simple-tabpanel-${category}`}
      aria-labelledby={`simple-tab-${category}`}
      {...rest}
    >
      {category === selectedCategory && children}
    </div>
  );
}
