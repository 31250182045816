import clsx from "clsx";
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";

import { Paper } from "atoms";
import { useDisplay } from "utils/theme";
import { fullDate } from "utils/formatters";

import NavLinks from "./NavLinks";

function campaignDate(date, display) {
  const month = display.widescreen ? "long" : "short";
  return fullDate(date, { month });
}

const useStyles = makeStyles((theme) => ({
  littleCircle: {
    minWidth: "16px",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
  },
  green: {
    backgroundColor: theme.palette.success.main,
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
  activePaper: {
    backgroundColor: "#E6E9F4",
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: 7,
    boxShadow: "0 5px 15px #15223214",
  },
  mobilePaper: {
    borderColor: theme.palette.primary.main,
  },
}));

export default function CampaignBox({
  id,
  campaign,
  activeCampaignId,
  variant,
}) {
  const [hovered, setHovered] = useState(false);
  const classes = useStyles();
  const display = useDisplay();
  return (
    <Paper
      display="flex"
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
      minHeight="155px"
      p={2}
      my={2}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      className={clsx(
        activeCampaignId === String(id) && classes.activePaper,
        !display.desktop && classes.mobilePaper
      )}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h3">{campaign.name}</Typography>
          <Typography variant="subtitle2">
            {campaignDate(campaign.media_flight_start_at, display)} -{" "}
            {campaignDate(campaign.media_flight_end_at, display)}
          </Typography>
        </Box>
        {["active", "completed"].indexOf(variant) !== -1 ? (
          <Box
            className={clsx(
              classes.littleCircle,
              variant === "active" ? classes.green : classes.red
            )}
          />
        ) : (
          <CheckCircle size="small" color="primary" />
        )}
      </Box>
      {(hovered || String(id) === activeCampaignId || !display.desktop) && (
        <Box mt={2}>
          <NavLinks campaignId={id} />
        </Box>
      )}
    </Paper>
  );
}
