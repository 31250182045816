import React, { useReducer } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { inputsReducer } from "utils";
import { Modal, Paper } from "atoms";

// Using a map lets us order the fields when we map them in the JSX.
const FIELDS = new Map([
  [
    "upc_col",
    {
      label: "UPC",
      tooltip:
        "This should be the exact column header name for the most detailed and unique item identifier. UPC is preferred, but (depending on retailer) sometimes Item ID, or combination of a number and item name are the best fit.",
    },
  ],
  [
    "location_num_col",
    {
      label: "Location Number",
      tooltip:
        "If the name of the column for uniquely identifying Location Number is different than what is pre-populated, please enter the exact header name of the relevant column. This column can be populated with a numeric Store Number, a unique name/number combination to represent the Store Location, or even Region/DMA.",
    },
  ],
  // ["brand_col", "Brand"],
  [
    "item_num_col",
    {
      label: "Item Number",
      tooltip:
        "This is the column in the data that represents the unique identifying number the retailer assigned to that item.",
    },
  ],
  [
    "item_description_col",
    {
      label: "Item Description",
      tooltip:
        "This is the unique item level description associated with the specific UPC.",
    },
  ],
  [
    "address_line_1_col",
    {
      label: "Address Line 1",
      tooltip:
        "Having the accurate column matching for Address Line 1, Address Line 2, City, State, Zip is important for media targeting. If a column does not exist in your sales data set, feel free to skip.",
    },
  ],
  ["address_line_2_col", { label: "Address Line 2" }],
  ["city_col", { label: "City" }],
  ["state_col", { label: "State" }],
  // ["country_col", "Country"],
  ["zip_code_col", { label: "ZIP Code" }],
]);

const useStyles = makeStyles((theme) => ({
  tooltipPaper: {
    backgroundColor: theme.palette.background.default,
  },
  tooltipIcon: {
    marginLeft: 10,
    marginRight: 10,
  },
  tooltipText: {
    marginRight: 10,
  },
  textField: {
    width: "100%",
  },
}));

export default function EditFieldNamesModal({
  open,
  setOpen,
  setFieldNames,
  savedFieldNames,
}) {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const classes = useStyles();

  const initialState = {};
  for (let f of FIELDS.keys()) {
    // use saved field names if they exist
    initialState[f] = savedFieldNames[f]
      ? savedFieldNames[f]
      : campaignDetailsState.campaignDetails[f];
  }
  const [inputsState, inputsDispatch] = useReducer(inputsReducer, initialState);
  const [selectedTooltip, setSelectedTooltip] = React.useState(null);

  function handleTooltipClick(target) {
    if (target === selectedTooltip) {
      setSelectedTooltip(null);
    } else {
      setSelectedTooltip(target);
    }
  }

  function handleChange(target, payload) {
    inputsDispatch({
      target,
      payload,
    });
  }

  function handleSave(e) {
    e.preventDefault();
    setFieldNames(inputsState);
    setOpen(false);
  }

  return (
    <Modal
      label="Confirm Data File Matching Modal"
      open={open}
      setOpen={setOpen}
    >
      <form onSubmit={handleSave}>
        <Paper
          width={964}
          height={650}
          p={5}
          px={10}
          display="flex"
          flexDirection="column"
        >
          <Box mb={2}>
            <Typography variant="h2">Confirm Data File Matching</Typography>
          </Box>
          <Typography variant="body1" gutterBottom>
            In order for our system to properly analyze your data - if your data
            file has different column names than what is pre-populated under the
            headers, please enter the exact column name from your data file.
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Box width={450}>
              {Array.from(FIELDS.entries())
                .slice(0, 4)
                .map(([key, { label, tooltip }]) => (
                  <Box display="flex" mb={3} key={key}>
                    <TextField
                      className={classes.textField}
                      label={label}
                      onChange={(e) => {
                        handleChange(key, e.target.value);
                      }}
                      value={inputsState[key]}
                      required={
                        ["upc_col", "location_num_col"].indexOf(key) !== -1
                      }
                      variant="outlined"
                    />
                    {tooltip && (
                      <IconButton
                        onClick={() => {
                          handleTooltipClick(key);
                        }}
                      >
                        <Help />
                      </IconButton>
                    )}
                  </Box>
                ))}
              {selectedTooltip !== null ? (
                <Paper
                  className={classes.tooltipPaper}
                  width={450}
                  height={150}
                  alignSelf="center"
                  display="flex"
                  alignItems="center"
                >
                  <Help className={classes.tooltipIcon} />
                  <Typography variant="caption" className={classes.tooltipText}>
                    {FIELDS.get(selectedTooltip).tooltip}
                  </Typography>
                </Paper>
              ) : (
                <Box />
              )}
            </Box>
            <Box width="40%">
              {Array.from(FIELDS.entries())
                .slice(4)
                .map(([key, { label, tooltip }]) => (
                  <Box display="flex" mb={3} key={key}>
                    <TextField
                      className={classes.textField}
                      label={label}
                      onChange={(e) => {
                        handleChange(key, e.target.value);
                      }}
                      value={inputsState[key]}
                      required={
                        ["upc_col", "location_num_col"].indexOf(key) !== -1
                      }
                      variant="outlined"
                    />
                    {tooltip && (
                      <IconButton
                        onClick={() => {
                          handleTooltipClick(key);
                        }}
                      >
                        <Help />
                      </IconButton>
                    )}
                  </Box>
                ))}
              <Box mt={5} display="flex" justifyContent="space-between">
                <Box mr={3}>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </Box>
                <Button
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </form>
    </Modal>
  );
}
