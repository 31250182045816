import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useTheme } from "@material-ui/core/styles";

import { useMetricsContext } from "context/metrics";
import { getThemeColor, useDisplay } from "utils/theme";

export default function SmallChart({ metric }) {
  const theme = useTheme();
  const { metricsState } = useMetricsContext();
  const { data } = metricsState;
  const display = useDisplay();

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: "area",
          height: display.widescreen ? 80 : 60,
          width: display.widescreen ? 155 : 101,
          events: {
            // force an extra reflow on load to resolve overflow issues
            load() {
              setTimeout(this?.reflow?.bind(this), 0);
            },
          },
        },
        plotOptions: {
          area: {
            marker: { enabled: false },
            fillColor: {
              linearGradient: [0, 0, 0, display.widescreen ? 80 : 50],
              stops: [
                [0, getThemeColor(metric, theme)],
                [
                  1,
                  Highcharts.color(getThemeColor(metric, theme))
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
          },
          series: { enableMouseTracking: false },
        },
        title: {
          text: null,
        },
        xAxis: { visible: false },
        yAxis: { visible: false },
        series: [
          {
            data: data[metric] ? [...data[metric].overTime] : [],
            color: getThemeColor(metric, theme),
            name: String(metric),
          },
        ],
        tooltip: { enabled: false },
        legend: { enabled: false },
        credits: { enabled: false },
        exporting: { enabled: false },
      }}
    />
  );
}
