import React from "react";

import { Box, Typography } from "@material-ui/core";

import { Paper } from "atoms";

import ItemPerformanceTable from "./ItemPerformanceTable";

export default function ItemPerformanceCategory({
  title,
  itemSet, //like campaignDetailsState.roiResults.feature_items
  totals,
}) {
  return (
    <Paper my={2} py={5} px={3} width="100%">
      <Box mb={2}>
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
      </Box>
      <ItemPerformanceTable itemSet={itemSet} totals={totals} />
    </Paper>
  );
}
