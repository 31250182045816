import React from "react";
import { useParams } from "react-router-dom";

import { useCampaignsContext } from "context/campaigns";

import SelectCampaignsCategory, { TabPanel } from "./SelectCampaignsCategory";
import CampaignsCategory from "./CampaignsCategory";
import { Box } from "@material-ui/core";

export default function CampaignsList() {
  const { campaignId } = useParams();
  const { campaignsState } = useCampaignsContext();
  const [category, setCategory] = React.useState("active"); // or "upcoming" or "complete"

  React.useEffect(() => {
    if (campaignId && campaignsState.campaigns[campaignId]) {
      setCategory(campaignsState.campaigns[campaignId].status);
    }
  }, [campaignId, campaignsState.campaigns]);

  return (
    <>
      <SelectCampaignsCategory
        setCategory={setCategory}
        selectedCategory={category}
      />
      <Box overflow="auto">
        <TabPanel selectedCategory={category} category="active">
          <CampaignsCategory
            variant="active"
            campaignIds={campaignsState.active}
          />
        </TabPanel>
        <TabPanel selectedCategory={category} category="upcoming">
          <CampaignsCategory
            variant="upcoming"
            campaignIds={campaignsState.upcoming}
          />
        </TabPanel>
        <TabPanel selectedCategory={category} category="completed">
          <CampaignsCategory
            variant="completed"
            campaignIds={campaignsState.completed}
          />
        </TabPanel>
      </Box>
    </>
  );
}
