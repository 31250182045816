import React from "react";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { saveLocationListPlan } from "context/campaignDetails/actions";
import { usePreCampaignWizardContext } from "context/preCampaignWizard";
import { ConfirmModal } from "atoms";

export default function SaveLocationListModal({ open, setOpen }) {
  const { wizardUtils } = usePreCampaignWizardContext();
  const { campaignDetailsDispatch } = useCampaignDetailsContext();
  function onConfirm() {
    wizardUtils.asyncCallbackThenStep(
      async () => await campaignDetailsDispatch(saveLocationListPlan)
    );
  }
  return (
    <ConfirmModal
      onConfirm={onConfirm}
      ariaLabel="Confirm Save Store List"
      open={open}
      setOpen={setOpen}
      text="Save Test Control Store List Plan?"
    />
  );
}
