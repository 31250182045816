import { apiHandler } from "utils/api";

export default async function postSalesData(
  campaignId,
  salesDataFile,
  columnMap,
  bypassML
) {
  const formData = new FormData();
  formData.append("sales_data", salesDataFile);
  formData.append("bypass_ml", bypassML);
  Object.entries(columnMap).forEach(([key, val]) => {
    formData.append(key, val);
  });

  return await apiHandler.post(
    `/campaigns/${campaignId}/sales-data`,
    formData,
    {
      headers: { "content-type": "multipart/form-data" },
    }
  );
}
