import React from "react";

import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { usePreCampaignWizardContext } from "context/preCampaignWizard";
import { Paper } from "atoms";
import { SalesDataChart } from "atoms/campaign";

import TestControlDetails from "./TestControlDetails";
import OverrideStoreListModal from "./OverrideStoreListModal";
import SaveLocationListModal from "./SaveLocationListModal";

const useStyles = makeStyles((theme) => ({
  littleCircle: {
    minWidth: "16px",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(4),
  },
  navButton: {
    borderRadius: 8,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  disabledNavButton: {
    backgroundColor: `${theme.palette.action.disabledBackground} !important`,
    color: `${theme.palette.action.disabled} !important`,
  },
  bold: {
    fontWeight: 700,
  },
  outlinedButtonOnGray: {
    backgroundColor: "#FFF",
  },
}));

export default function ViewCampaignDetails() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const { salesData } = campaignDetailsState;
  const { wizardUtils } = usePreCampaignWizardContext();
  const classes = useStyles();

  const [subpage, setSubpage] = React.useState(0);
  const [overrideModalOpen, setOverrideModalOpen] = React.useState(false);
  const [confirmStoreListModalOpen, setConfirmStoreListModalOpen] =
    React.useState(false);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Paper
          variant="outlined"
          p={5}
          pb={3}
          display="flex"
          flexDirection="column"
          mb={3}
          overflow="hidden"
          width={1000}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h3" component="h2">
              4. CONFIRM TEST CONTROL
            </Typography>
            <Box display="flex" alignItems="center">
              <Box className={classes.littleCircle} />
              <Typography variant="h3">
                {subpage === 0
                  ? "Test Control Details"
                  : "Test Control Details Comparison"}
              </Typography>
            </Box>
          </Box>
          {subpage === 0 ? (
            <TestControlDetails />
          ) : salesData?.dollar_sums ? (
            <Box height={290} py={3}>
              <SalesDataChart height={263} initialDataSet="units" />
            </Box>
          ) : (
            <Typography variant="body1">
              Upload Store List to View Sales Data
            </Typography>
          )}
          <Box
            alignSelf="center"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="15%"
            mt={3}
          >
            <IconButton
              size="small"
              className={classes.navButton}
              classes={{
                disabled: classes.disabledNavButton,
              }}
              onClick={() => setSubpage(0)}
              disabled={subpage === 0}
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.bold}
            >
              {subpage + 1}/2
            </Typography>
            <IconButton
              size="small"
              className={classes.navButton}
              classes={{
                disabled: classes.disabledNavButton,
              }}
              onClick={() => setSubpage(1)}
              disabled={subpage === 1}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Box>
        </Paper>
        <Box display="flex" justifyContent="space-between">
          <Button
            onClick={wizardUtils.prevStep}
            size="large"
            startIcon={<ArrowBackIosOutlinedIcon />}
          >
            PREVIOUS
          </Button>
          <Box display="flex">
            <Box mr={3}>
              <Button
                className={classes.outlinedButtonOnGray}
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => {
                  setOverrideModalOpen(true);
                }}
              >
                Override Store List
              </Button>
            </Box>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={() => {
                setConfirmStoreListModalOpen(true);
              }}
            >
              Save Control List
            </Button>
          </Box>
        </Box>
      </Box>
      <OverrideStoreListModal
        open={overrideModalOpen}
        setOpen={setOverrideModalOpen}
      />
      <SaveLocationListModal
        open={confirmStoreListModalOpen}
        setOpen={setConfirmStoreListModalOpen}
      />
    </>
  );
}
