import { parseDate } from "utils/api";
import { useCampaignDetailsContext } from "context/campaignDetails";

export const fullDate = (
  inDate,
  { year = "numeric", month = "short", day = "numeric" } = {}
) => {
  if (typeof inDate === "string") {
    inDate = parseDate(inDate);
  }
  return inDate.toLocaleDateString("en-US", {
    year,
    month,
    day,
  });
};

export const shortDate = (inDate) => {
  if (typeof inDate === "string") {
    inDate = parseDate(inDate);
  }
  // Month is zero-indexed
  const month = inDate.getMonth() + 1;
  const day = inDate.getDate();
  const year = String(inDate.getFullYear()).slice(2);
  return `${month}/${day}/${year}`;
};

export const formatCurrency = (num, useCents = false) => {
  if (useCents) {
    return (
      "$" +
      Number(num)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  } else {
    return (
      "$" +
      Number(num)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  }
};

export const formatPercentage = (num, decimals = 2) => {
  return Number(num).toFixed(decimals) + "%";
};

export const titleCase = (str) => {
  const arr = str.split(" ");
  return arr.reduce(
    (acc, word) => `${acc} ${word[0].toUpperCase() + word.slice(1)}`,
    ""
  );
};

export function useTotalOrNoMeasurableLift() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const { remove_negative_lift } = campaignDetailsState.roiResults.filters;
  return function totalOrNoMeasurableLift(total, format) {
    if (remove_negative_lift && total <= 0) {
      return "No Lift";
    }
    switch (format) {
      case "$":
        return formatCurrency(total, true);
      case "%":
        return formatPercentage(Number(total) * 100);
      default:
        return Number(total).toFixed(2);
    }
  };
}
