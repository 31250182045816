import axios from "axios";

export default async function getCellsByRangeAndId(
  documentId,
  range,
  sheetName,
  majorDimension = "COLUMNS"
) {
  let response;
  let error;
  try {
    response = await axios.get(
      `https://content-sheets.googleapis.com/v4/spreadsheets/${documentId}/values/${sheetName}!${range}?key=${process.env.REACT_APP_GAPI_KEY}&majorDimension=${majorDimension}`
    );
  } catch (e) {
    error = e;
  }
  return [response, error];
}
