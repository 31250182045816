import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useDisplay } from "utils/theme";
import { Paper } from "atoms";
import { titleCase } from "utils/formatters";

function formatTooltip(
  tooltip,
  x = this.x,
  y = this.y,
  series = this.series,
  color = this.color
) {
  var dateString = Highcharts.dateFormat("%b %e", x);
  return `<b>${dateString}</b><br/><span style="color:${color};">${series.name}: ${y}</span>`;
}

export default function MetricOverTimeChart({ metric, data, color }) {
  const display = useDisplay();
  return (
    <Paper p={3} height={display.widescreen ? 423 : 301}>
      <Typography variant="h3">{titleCase(metric)}</Typography>
      <Typography variant="subtitle2">
        Total {titleCase(metric)} Over Time
      </Typography>
      <Box flexGrow={1} overflow="hidden" flexBasis={0}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "area",
              height: display.widescreen ? 320 : 200,
              events: {
                // force an extra reflow on load to resolve overflow issues
                load() {
                  setTimeout(this?.reflow?.bind(this), 0);
                },
              },
              style: {
                fontFamily: "Poppins",
              },
            },
            plotOptions: {
              area: {
                marker: {
                  enabled: false,
                },
                fillColor: {
                  linearGradient: [0, 0, 0, display.widescreen ? 400 : 200],
                  stops: [
                    [0, color],
                    [1, Highcharts.color(color).setOpacity(0).get("rgba")],
                  ],
                },
              },
            },
            title: {
              text: null,
            },
            xAxis: {
              lineWidth: 0,
              tickLength: 0,
              crosshair: true,
              type: "datetime",
              labels: {
                formatter: function () {
                  return Highcharts.dateFormat("%b %e", this.value);
                },
              },
              gridLineWidth: 1,
            },
            yAxis: {
              min: 0,
              title: null,
              gridLineWidth: 0,
            },
            series: [
              {
                data: [...data],
                color,
                name: metric,
              },
            ],
            tooltip: { formatter: formatTooltip },
            legend: { enabled: false },
            credits: { enabled: false },
            exporting: { enabled: false },
          }}
        />
      </Box>
    </Paper>
  );
}
