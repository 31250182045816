import React from "react";

import Box from "@material-ui/core/Box";

import { useDisplay } from "utils/theme";

import AppBar from "./AppBar";
import SideNav from "./SideNav";

export default function Layout({ children }) {
  const display = useDisplay();
  if (display.mobile) {
    return (
      <Box overflow="auto" width="100%" height="100%">
        <AppBar />
        <Box display="flex" width="100%" height="100%" flexDirection="column">
          {children}
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" width="100%" height="100%">
      {display.desktop && <SideNav />}
      <Box overflow="auto" width="100%">
        {children}
      </Box>
    </Box>
  );
}
