import React from "react";

import Box from "@material-ui/core/Box";

import {
  CampaignDatesBox,
  CampaignNamePageHeaderWithSubtitle,
} from "atoms/campaign";
import SelectMediaLineItem from "./SelectMediaLineItem";
import SelectMetricType from "./SelectMetricType";

export default function DesktopHeader() {
  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={4}>
        <CampaignNamePageHeaderWithSubtitle subtitle="Metrics Tracker" />
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <CampaignDatesBox />
          <Box>
            <SelectMediaLineItem />
          </Box>
        </Box>
      </Box>
      <SelectMetricType />
    </>
  );
}
