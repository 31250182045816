import { getMetricDetails } from "utils/gapi";

// This action is meant to be called by other actions,
// so it doesn't set loading.
export default function setMetricDetails(sheetId, hasClickData) {
  return async function selectMediaLineItemAction(metricsDispatch) {
    const impressions = await getMetricDetails(sheetId, "impressions");
    const activations = await getMetricDetails(sheetId, "activations");
    let clicks;
    if (hasClickData) {
      clicks = await getMetricDetails(sheetId, "clicks");
    }
    metricsDispatch({
      type: "SET_METRIC_DETAILS",
      payload: {
        impressions,
        clicks,
        activations,
      },
    });
  };
}
