import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export function getThemeColor(key, theme) {
  // Colors in the theme are either strings or objects.
  // Return the "main" value of the object, or the string.
  if (typeof theme.palette[key] === "object") {
    return theme.palette[key].main;
  }
  return theme.palette[key];
}

export function useDisplay() {
  // returns an object.
  // widescreen and desktop are based on min-width
  // mobile based on max-width
  // tablet and mobile can both be true
  const { breakpoints } = useTheme();
  const { md, lg, xl } = breakpoints.values;
  const widescreen = useMediaQuery(`(min-width:${xl}px)`);
  const desktop = useMediaQuery(`(min-width:${lg}px)`);
  const tablet = useMediaQuery(`(min-width:${md}px)`);
  const mobile = useMediaQuery(`(max-width:${lg - 1}px)`);
  return {
    widescreen,
    desktop,
    tablet,
    mobile,
  };
}
