export const initialState = {
  mediaLineItemsList: [
    //list of backend.campaigns.serializers.MediaLineItemDetails
  ],
  selectedMediaLineItem: 0, //index of the mediaLineItemsList that is selectedMediaLineItem.
  metricType: "impressions",
  // metricDetails just points to whichever key of `data` matches metricType
  // This it because we used to only store 1 metric type at a time
  // And it's a lot of work to refactor.
  // See "SET_METRIC_TYPE" and "SET_METRIC_DETAILS" actions for how this is kept in sync
  metricDetails: null,
  data: {
    impressions: null,
    clicks: null,
    activations: null,
  },
  loading: true,
};

export function reducer(currentState, action) {
  switch (action.type) {
    case "SET_LOADING": {
      return {
        ...currentState,
        loading: action.payload,
      };
    }
    case "SET_MEDIA_LINE_ITEMS_LIST": {
      return {
        ...currentState,
        mediaLineItemsList: action.payload,
      };
    }
    case "SET_SELECTED_MEDIA_LINE_ITEM": {
      return {
        ...currentState,
        selectedMediaLineItem: action.payload,
      };
    }
    case "SET_METRIC_TYPE": {
      return {
        ...currentState,
        metricType: action.payload,
        metricDetails: currentState.data[action.payload],
      };
    }
    case "SET_METRIC_DETAILS": {
      return {
        ...currentState,
        data: action.payload,
        metricDetails: action.payload[currentState.metricType],
      };
    }
    default:
      throw new Error(
        `MetricsProvider dispatch received unhandled dispatch action type: ${action.type}`
      );
  }
}
