import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CancelRounded";

import { Paper } from "atoms";
import { CampaignNamePageHeaderWithSubtitle } from "atoms/campaign";

import SelectMediaLineItem from "./SelectMediaLineItem";
import SelectMetricType from "./SelectMetricType";

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

export default function MobileHeader() {
  const classes = useStyles();
  return (
    <Paper p={2} elevation={0} variant="outlined" className={classes.root}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={2}
      >
        <CampaignNamePageHeaderWithSubtitle subtitle="Metrics Tracker" />
        <IconButton component={Link} to="/" aria-label="Back to campaigns list">
          <CloseIcon fontSize="large" className={classes.icon} />
        </IconButton>
      </Box>
      <Box mb={3}>
        <SelectMediaLineItem />
      </Box>
      <SelectMetricType />
    </Paper>
  );
}
