import React from "react";

import { useDisplay } from "utils/theme";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

export default function Metrics() {
  const display = useDisplay();

  return <>{display.tablet ? <Desktop /> : <Mobile />}</>;
}
