import getCellsByRangeAndId from "./getCellsByRangeAndId";
import parseDate from "./parseDate";

const GOOGLE_SHEETS_TAB_NAMES = {
  impressions: "Page 1 - Impressions",
  activations: "Page 2 - Activations",
  clicks: "Page 3 - Clicks",
};

export default async function getMetricDetails(
  pageId,
  metricType = "impressions"
) {
  const [response, error] = await getCellsByRangeAndId(
    pageId,
    "A1:P100",
    GOOGLE_SHEETS_TAB_NAMES[metricType]
  );
  if (error) {
    console.error("failed to fetch data from google sheet");
    // return empty values
    return {
      totals: [],
      overTime: [],
      ageGroup: [],
      gender: [],
      geography: [],
    };
  }
  const data = response.data.values;

  // "B3:D3"
  let totals = [data[1][2], data[2][2], data[3][2]];
  // "B8:C100"
  let overTime = [data[1].slice(7, 100), data[2].slice(7, 100)];
  overTime = overTime[0].map((date, i) => [
    parseDate(date).getTime(),
    Number(overTime[1][i]),
  ]);
  // "G2:H100"
  let ageGroup = [data[6].slice(1, 100), data[7].slice(1, 100)];
  ageGroup = ageGroup[0].map((range, i) => [range, Number(ageGroup[1][i])]);
  //"K2:L4"
  let gender = [data[10].slice(1, 4), data[11].slice(1, 4)];
  gender = gender[0].map((range, i) => [range, Number(gender[1][i])]);
  // "O2:P70"
  let geography = [data[14].slice(1, 70), data[15].slice(1, 70)];
  geography = geography[0].reduce((acc, range, i) => {
    const val = Number(geography[1][i]);
    if (val > 0) {
      acc.push([range, val]);
    }
    return acc;
  }, []);

  return {
    totals,
    overTime,
    ageGroup,
    gender,
    geography,
  };
}
