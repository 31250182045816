import { patchCampaign } from "utils/api/campaigns";

export default async function resetCampaignDetails(
  campaignDetailsDispatch,
  campaignDetailsState
) {
  const [response, error] = await patchCampaign(
    campaignDetailsState.campaignDetails.id,
    {
      reset_campaign_details: true,
    }
  );
  if (error) {
    throw new Error(error);
  } else {
    campaignDetailsDispatch({
      type: "SET_CAMPAIGN",
      payload: response.data,
    });
  }
}
