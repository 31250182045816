import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useCustomReducer } from "utils";

import { getCampaignDetails } from "./actions";
import { reducer, initialState } from "./reducer";

const CampaignDetailsContext = React.createContext();

export function CampaignDetailsProvider({ children }) {
  const { campaignId } = useParams();
  const [campaignDetailsState, campaignDetailsDispatch] = useCustomReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    async function loadCampaign() {
      campaignDetailsDispatch({
        type: "SET_LOADING",
        payload: true,
      });
      campaignDetailsDispatch({
        type: "SET_ERROR",
        payload: null,
      });
      try {
        await campaignDetailsDispatch(getCampaignDetails(campaignId));
      } catch (error) {
        campaignDetailsDispatch({
          type: "SET_ERROR",
          payload: error,
        });
      }
      campaignDetailsDispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
    loadCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return (
    <CampaignDetailsContext.Provider
      value={{ campaignDetailsState, campaignDetailsDispatch }}
    >
      {campaignDetailsState.loading ? (
        <CircularProgress />
      ) : campaignDetailsState.error ? (
        // TODO: Make a real 404 page.
        <>
          <p>Could not load campaign with ID: {campaignId}</p>
          <p>{String(campaignDetailsState.error)}</p>
        </>
      ) : (
        children
      )}
    </CampaignDetailsContext.Provider>
  );
}

export function useCampaignDetailsContext() {
  const context = useContext(CampaignDetailsContext);
  if (context === undefined) {
    throw new Error(
      "useCampaignDetailsContext must be used within a CampaignDetailsProvider"
    );
  }
  return context;
}
