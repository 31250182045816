const palette = {
  primary: {
    main: "#1B5AD2",
    dark: "#29344A",
  },
  secondary: {
    main: "#2BD090",
    contrastText: "#FFF",
  },
  success: {
    main: "#56C763",
  },
  background: {
    default: "#F4F6F8",
  },
  text: {
    primary: "#131523",
    secondary: "#7E84A3",
  },
  action: {
    active: "#29344A",
  },
  divider: "#E6E9F4",
  impressions: "#5142C3",
  activations: "#F42A7F",
  clicks: "#35C6F2",
};

const typography = {
  fontFamily: `'Roboto', sans-serif`,
  textAlign: "left",
  h1: {
    fontSize: "28px",
    fontWeight: 700,
  },
  h2: {
    fontSize: "22px",
    fontWeight: 700,
  },
  h3: {
    fontSize: "18px",
    fontWeight: 700,
  },
  h4: {
    fontSize: "16px",
  },
  h5: {
    fontSize: "12px",
  },
  subtitle1: {
    fontSize: "22px",
    color: palette.text.secondary,
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: "14px",
    fontWeight: 400,
    color: palette.text.secondary,
  },
  body1: {
    fontFamily: `'Poppins', sans-serif`,
    fontSize: "12px",
    letterSpacing: "0.5px",
  },
  body2: {
    fontSize: "18px",
    fontWeight: 400,
  },
  caption: {
    fontSize: "14px",
    lineHeight: 1.25,
  },
  button: {
    fontSize: "12px",
    fontWeight: 500,
    textTransform: "none",
  },
};

const overrides = {
  MuiTableRow: {
    root: {
      verticalAlign: "bottom",
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: `1px solid ${palette.divider}`,
    },
    sizeSmall: {
      fontSize: "12px",
      fontFamily: `'Poppins', sans-serif`,
      color: palette.text.secondary,
      padding: "10px 0px",
    },
    head: {
      color: `${palette.text.primary} !important`,
      // easier to set this and add `<br />` where necessary to wrap.
      whiteSpace: "nowrap",
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 6,
      boxShadow: "0 1px 4px #15223214 !important",
    },
    outlined: {
      boxShadow: "none",
      borderColor: palette.text.secondary,
      borderWidth: "0.5px",
      borderRadius: 15,
    },
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: 50,
    },
  },
  // Allows the SideNav tabs to fit in the container instead of scroll.
  MuiTab: {
    root: {
      "@media (min-width:600px)": {
        minWidth: undefined,
      },
    },
  },
  MuiAccordion: {
    rounded: {
      borderRadius: 8,
    },
  },
  MuiButton: {
    sizeLarge: {
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 6,
    },
    contained: {
      boxShadow: "none",
    },
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1366,
    xl: 1920,
  },
};

const dashboardTheme = {
  palette,
  typography,
  overrides,
  breakpoints,
};

const loginPalette = {
  ...palette,
  background: {
    default: "#000",
  },
  type: "dark",
};

const loginTypography = {
  ...typography,
  h1: {
    ...typography.h1,
    color: "#fff",
  },
  h2: {
    ...typography.h2,
    color: undefined,
  },
  caption: {
    ...typography.caption,
    color: "#fff",
  },
};

const loginTheme = {
  palette: loginPalette,
  typography: loginTypography,
};

export { dashboardTheme, loginTheme };
