import { useReducer } from "react";

export default function useCustomReducer(reducer, initialState, init) {
  // Just a wrapper for useReducer that lets you pass a function that needs dispatch.
  const [state, dispatch] = useReducer(reducer, initialState, init);

  function thunkDispatch(action) {
    if (typeof action === "function") {
      return action(thunkDispatch, state);
    } else {
      dispatch(action);
    }
  }

  return [state, thunkDispatch];
}
