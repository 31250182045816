import React from "react";
import { Box, TextField, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { DatePicker, Select } from "molecules/Form";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

export default function ProgramDetails({
  handleChange,
  inputsState,
  handleTooltipClick,
}) {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" width="100%" px="82px">
      <Box width={380} mr="80px">
        <Box mb={3} display="flex" justifyContent="space-between">
          <TextField
            className={classes.fullWidth}
            label="Campaign Name"
            onChange={(e) => {
              handleChange("name", e.target.value);
            }}
            value={inputsState.name}
            required
            variant="outlined"
          />
          <IconButton
            onClick={() => {
              handleTooltipClick("name");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box mb={3} display="flex" justifyContent="space-between">
          <TextField
            className={classes.fullWidth}
            label="Budget"
            type="number"
            InputProps={{
              inputProps: { min: 1 },
            }}
            onChange={(e) => {
              handleChange("budget", Number(e.target.value));
            }}
            value={String(inputsState.budget)}
            required
            variant="outlined"
          />
          <IconButton
            onClick={() => {
              handleTooltipClick("budget");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box mb={3}>
          <DatePicker
            className={classes.fullWidth}
            label="Media Flight Start Date"
            onChange={(date) => {
              handleChange("mediaFlightStart", date);
            }}
            value={inputsState.mediaFlightStart}
            required
            variant="outlined"
          />
        </Box>
      </Box>
      <Box width={380}>
        <Box display="flex" width="100%" mb={3}>
          <Select
            className={classes.fullWidth}
            label="Channel Type"
            onChange={(e) => {
              handleChange("channelType", e.target.value);
            }}
            options={[
              {
                value: "BRICK_AND_MORTAR",
                label: "Brick & Mortar",
              },
              {
                value: "E_COMM",
                label: "eComm",
              },
              {
                value: "OMNI",
                label: "Omni",
              },
            ]}
            value={inputsState.channelType}
            required
          />
          <Box minWidth={119} ml={3}>
            <TextField
              label="Store Count"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              onChange={(e) => {
                handleChange("desiredLocationCount", Number(e.target.value));
              }}
              value={String(inputsState.desiredLocationCount)}
              required
              variant="outlined"
            />
          </Box>
          <IconButton
            onClick={() => {
              handleTooltipClick("desiredLocationCount");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box mb={3} display="flex" justifyContent="space-between">
          <TextField
            className={classes.fullWidth}
            variant="outlined"
            label="Retailer"
            onChange={(e) => {
              handleChange("retailer", e.target.value);
            }}
            value={inputsState.retailer}
            required
          />
          <IconButton
            onClick={() => {
              handleTooltipClick("retailer");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box mb={3} width="100%">
          <DatePicker
            className={classes.fullWidth}
            label="Media Flight End Date"
            onChange={(date) => {
              handleChange("mediaFlightEnd", date);
            }}
            value={inputsState.mediaFlightEnd}
            minDate={inputsState.mediaFlightStart}
            minDateMessage="Cannot be before Start Date"
            required
            variant="outlined"
          />
        </Box>
      </Box>
    </Box>
  );
}
