import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { getCampaignDetails } from "context/campaignDetails/actions";
import { inputsReducer } from "utils";
import { Select } from "molecules/Form";

const BOOLEAN_FILTERS = [
  {
    key: "include_two_weeks_post",
    label: "Includes 2 weeks Post?",
  },
  {
    key: "remove_potential_merch_noise",
    label: "Removed potential Merch Noise?",
  },
  {
    key: "remove_negative_inventory_noise",
    label: "Removed Negative Inventory Noise?",
  },
  {
    key: "remove_negative_lift",
    label: "Negative lift counted as 0% lift?",
  },
];

const useStyles = makeStyles((theme) => ({
  accordionSummaryRoot: {
    justifyContent: "flex-end",
    minHeight: 40,
    maxHeight: 40,
  },
  accordionSummaryContent: {
    flexGrow: 0,
  },
  accordionText: {
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  accordionIcon: {
    color: theme.palette.text.secondary,
  },
}));

export default function ROIResultsFilters({ setLoading }) {
  const { campaignDetailsState, campaignDetailsDispatch } =
    useCampaignDetailsContext();

  const newItem = campaignDetailsState.campaignDetails.is_new_item;

  const prePeriodOptions = [
    {
      label: newItem ? "Difference Vs Control" : "Difference In Difference",
      value: "diff_in_diff",
    },
  ];
  for (let num of [4, 13, 26, 52]) {
    if (
      num <=
      campaignDetailsState.salesData.weeks_of_sales_data_before_media_flight
    ) {
      prePeriodOptions.push({
        label: `${num} Weeks Prior`,
        value: num,
      });
    } else {
      break;
    }
  }

  const {
    include_two_weeks_post,
    remove_potential_merch_noise,
    remove_negative_inventory_noise,
    remove_negative_lift,
  } = campaignDetailsState.roiResults.filters;
  const [inputsState, inputsDispatch] = React.useReducer(inputsReducer, {
    include_two_weeks_post,
    remove_potential_merch_noise,
    remove_negative_inventory_noise,
    remove_negative_lift,
    pre_period: "diff_in_diff",
  });

  function handleChange(target, payload) {
    inputsDispatch({
      target,
      payload,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    await campaignDetailsDispatch(
      getCampaignDetails(campaignDetailsState.campaignDetails.id, inputsState)
    );
    setLoading(false);
  }

  const classes = useStyles();

  return (
    <Accordion elevation={0} variant="outlined" style={{ position: "inherit" }}>
      <AccordionSummary
        className={classes.accordionSummaryRoot}
        classes={{ content: classes.accordionSummaryContent }}
        expandIcon={
          <ArrowDropDown className={classes.accordionIcon} fontSize="large" />
        }
      >
        <Typography variant="subtitle1" className={classes.accordionText}>
          Analysis Parameters
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            px={3}
          >
            <Box width="60%">
              <Select
                style={{ width: "100%" }}
                label={"Pre Period For Comparison"}
                onChange={(e) => {
                  handleChange("pre_period", e.target.value);
                }}
                options={prePeriodOptions}
                value={inputsState.pre_period}
              />
              <Box display="flex">
                <Box>
                  {BOOLEAN_FILTERS.slice(0, 2).map(({ key, label }) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          color="primary"
                          checked={inputsState[key]}
                          onChange={(e) => {
                            handleChange(key, e.target.checked);
                          }}
                          name={label}
                        />
                      }
                      label={label}
                    />
                  ))}
                </Box>
                <Box>
                  {BOOLEAN_FILTERS.slice(2).map(({ key, label }) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          color="primary"
                          checked={inputsState[key]}
                          onChange={(e) => {
                            handleChange(key, e.target.checked);
                          }}
                          name={label}
                        />
                      }
                      label={label}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              Apply Filters
            </Button>
          </Box>
        </form>
      </AccordionDetails>
    </Accordion>
  );
}
