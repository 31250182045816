import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { useMetricsContext } from "context/metrics";
import { selectMediaLineItem } from "context/metrics/actions";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  unSelectedButton: {
    backgroundColor: "#fff",
    color: theme.palette.text.secondary,
    borderColor: "#D7DBEC",
  },
  selectedButton: {
    boxShadow: "inset 0 3px 6px #00000029",
  },
}));

export default function SelectMediaLineItem() {
  const { metricsState, metricsDispatch } = useMetricsContext();
  const classes = useStyles();
  return (
    // Needs to be wrapped in Box to behave as a flex child.
    <Box>
      <ButtonGroup
        color="primary"
        size="small"
        aria-label="Select Media Line Item"
      >
        {metricsState.mediaLineItemsList.map((mediaLineItem, i) => (
          <Button
            key={mediaLineItem.name}
            style={{ padding: "0.5rem 2rem", whiteSpace: "nowrap" }}
            className={`
              ${
                metricsState.selectedMediaLineItem === i
                  ? classes.selectedButton
                  : classes.unSelectedButton
              }
            `}
            variant={
              metricsState.selectedMediaLineItem === i
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              if (metricsState.selectedMediaLineItem !== i) {
                metricsDispatch(selectMediaLineItem(i));
              }
            }}
          >
            {mediaLineItem.name}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
