import React from "react";

const DashboardLayoutContext = React.createContext();

export function DashboardLayoutProvider({ children }) {
  // Make sure not to set this if using layout outside of ROITrackerProvider
  const [narrowSideNav, setNarrowSideNav] = React.useState(false);
  // Allows children to control the behaviour for using the Exit Wizard button in the sidenav.
  const [
    dashboardRequestingNarrowSideNav,
    setDashboardRequestingNarrowSideNav,
  ] = React.useState(false);

  return (
    <DashboardLayoutContext.Provider
      value={{
        dashboardState: {
          narrowSideNav,
          dashboardRequestingNarrowSideNav,
        },
        dashboardDispatch: {
          setNarrowSideNav,
          setDashboardRequestingNarrowSideNav,
        },
      }}
    >
      {children}
    </DashboardLayoutContext.Provider>
  );
}

export function useDashboardContext() {
  const context = React.useContext(DashboardLayoutContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return context;
}
