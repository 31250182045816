import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";

import { Paper } from "atoms";
import { useAuthContext } from "context/auth";
import { logout } from "context/auth/actions";
import { useHistory } from "react-router-dom";
import { clientLogo } from "img/clientLogo";

function LogoutIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17 17H9v2h8c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2H9v2h8v14z" />
      <path d="M7 15v-4h8V9H7V5l-6 5z" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 8,
  },
  logo: {
    borderRadius: "50%",
    margin: "auto 0",
    verticalAlign: "middle",
    maxWidth: "60px",
    maxHeight: "60px",
  },
  whiteText: {
    color: "#FFF",
  },
  emailText: {
    color: "#FFF",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
  },
  logoBg: {
    backgroundColor: "#FFF",
    borderRadius: "50%",
    maxWidth: "60px",
    maxHeight: "60px",
  },
}));

export default function Header() {
  const classes = useStyles();
  const { authState, authDispatch } = useAuthContext();
  console.log(authState);
  let history = useHistory();
  return (
    <Paper
      className={classes.root}
      elevation={0}
      p={3}
      pt={3}
      pb={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        {/* Logo and User Info */}
        <Box mb={3} display="flex" alignItems="center">
          <Box className={classes.logoBg}>
            <img
              src={
                authState.user.groups && authState.user.groups[0]?.company?.logo
                  ? authState.user.groups[0]?.company?.logo
                  : clientLogo.src
              }
              onError={({ currentTarget }) => {
                currentTarget.onError = null; // prevents looping
                currentTarget.src = clientLogo.src;
              }}
              aria-label={`${authState.user.groups[0]?.name} Logo`}
              width={80}
              className={classes.logo}
            />
          </Box>
          <Box ml={4}>
            <Typography className={classes.whiteText} variant="h2">
              {authState.user.groups[0]?.name}
            </Typography>
            <Typography
              className={classes.emailText}
              variant="body1"
              title={authState.user.email}
            >
              {authState.user.email}
            </Typography>
          </Box>
        </Box>
        {/* Account Actions */}
        <Button
          className={classes.whiteText}
          startIcon={<LogoutIcon />}
          size="large"
          variant="text"
          onClick={async () => {
            await authDispatch(logout);
            history.replace("/");
          }}
        >
          Log Out
        </Button>
      </Box>
    </Paper>
  );
}
