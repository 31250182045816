import React, { useContext } from "react";
import { toast } from "react-toastify";

import { Backdrop } from "atoms";
import { useDashboardContext } from "context/dashboardLayout";
import { useCampaignDetailsContext } from "context/campaignDetails";

const MAIN_VIEWS = [
  "PRE_CAMPAIGN_LANDING_PAGE",
  "PRE_CAMPAIGN_WIZARD",
  "MID_CAMPAIGN_LANDING_PAGE",
  "POST_CAMPAIGN_WIZARD",
  "POST_CAMPAIGN_VIEW_ROI_RESULTS",
  "GENERAL_CAMPAIGN_DETAILS_PAGE",
];

const STARTING_POINTS_BASED_ON_ROI_STATE = {
  CREATED: "PRE_CAMPAIGN_LANDING_PAGE",
  CONFIRMED: "PRE_CAMPAIGN_LANDING_PAGE",
  PRE_BAD_DATA: "PRE_CAMPAIGN_LANDING_PAGE", //Might change later to a landing page or a separate page
  STORE_LIST_GEN_FAILED: "PRE_CAMPAIGN_LANDING_PAGE", //Might change later to a landing page or a separate page
  STORE_LIST_GENERATED: "PRE_CAMPAIGN_LANDING_PAGE",
  NEEDS_OVERRIDE: "MID_CAMPAIGN_LANDING_PAGE",
  AWAITING_DATA: "MID_CAMPAIGN_LANDING_PAGE",
  POST_BAD_DATA: "MID_CAMPAIGN_LANDING_PAGE", //Might change later to a landing page or a separate page
  ANOMALY_DETECTION_FAILED: "MID_CAMPAIGN_LANDING_PAGE", //Might change later to a landing page or a separate page
  PARTIAL_DATA: "POST_CAMPAIGN_VIEW_ROI_RESULTS",
  COMPLETE: "POST_CAMPAIGN_VIEW_ROI_RESULTS",
};

const ROITrackerContext = React.createContext();

function trackerEntryStep(campaignDetails) {
  return STARTING_POINTS_BASED_ON_ROI_STATE[campaignDetails.roi_state];
}

export function ROITrackerProvider({ children }) {
  const { campaignDetailsState } = useCampaignDetailsContext();

  const [mainView, setMainView] = React.useState(
    trackerEntryStep(campaignDetailsState.campaignDetails)
  );
  const [loading, setLoading] = React.useState(false);

  return (
    <ROITrackerContext.Provider
      value={{
        trackerState: {
          mainView,
          loading,
        },
        trackerDispatch: {
          setMainView,
          setLoading,
        },
      }}
    >
      <Backdrop open={loading} />
      {children}
    </ROITrackerContext.Provider>
  );
}

export function useTrackerContext() {
  const context = useContext(ROITrackerContext);
  if (context === undefined) {
    throw new Error(
      "useROITrackerContext must be used within a ROITrackerProvider"
    );
  }
  const { trackerState, trackerDispatch } = context;
  const { mainView } = trackerState;
  if (MAIN_VIEWS.indexOf(mainView) === -1) {
    // This would just be a programmer mistake.
    throw new Error(`Unexpected view ${mainView} requested.`);
  }
  const { campaignDetailsState } = useCampaignDetailsContext();

  function exitWizard() {
    trackerDispatch.setMainView(
      trackerEntryStep(campaignDetailsState.campaignDetails)
    );
  }

  async function asyncCallbackThenSetView(callback, targetView) {
    trackerDispatch.setLoading(true);
    try {
      await callback();
      trackerDispatch.setMainView(targetView);
    } catch (e) {
      toast.error(e.message);
    }
    trackerDispatch.setLoading(false);
  }

  const workingOnWizard = [
    "PRE_CAMPAIGN_WIZARD",
    "POST_CAMPAIGN_WIZARD",
    "GENERAL_CAMPAIGN_DETAILS_PAGE",
  ].includes(mainView);

  const { dashboardDispatch, dashboardState } = useDashboardContext();

  React.useEffect(() => {
    if (workingOnWizard) {
      dashboardDispatch.setNarrowSideNav(true);
    } else {
      dashboardDispatch.setNarrowSideNav(false);
    }
    return () => dashboardDispatch.setNarrowSideNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingOnWizard]);

  // Controls the Exit Wizard behaviour from the Dashboard Layout
  React.useEffect(() => {
    if (dashboardState.dashboardRequestingNarrowSideNav && workingOnWizard) {
      dashboardDispatch.setDashboardRequestingNarrowSideNav(false);
      dashboardDispatch.setNarrowSideNav(false);
      exitWizard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.dashboardRequestingNarrowSideNav, workingOnWizard]);

  return {
    ...context,
    trackerUtils: {
      exitWizard,
      asyncCallbackThenSetView,
    },
  };
}
