import React from "react";
import { Box, Typography } from "@material-ui/core";

import { usePreCampaignWizardContext } from "context/preCampaignWizard";

import ConfirmCampaignDetailsForm from "./ConfirmCampaignDetailsForm";
import UploadSalesData from "./UploadSalesData";
import ConfirmLocationList from "./ConfirmLocationList";
import WizardCompleteConfirmationPage from "./WizardCompleteConfirmationPage";
import WizardStepper from "./Stepper";

export default function PreCampaignSetupWizard() {
  const { wizardState, stepperSteps, wizardUtils } =
    usePreCampaignWizardContext();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="100%"
    >
      {wizardState.step === "WIZARD_COMPLETE_CONFIRMATION" ? (
        <WizardCompleteConfirmationPage />
      ) : (
        <Box width={1000} mt={3}>
          <Typography component="h1" variant="h2">
            PRE-CAMPAIGN SET-UP
          </Typography>
          <WizardStepper
            steps={stepperSteps}
            activeStep={wizardUtils.activeStepIndex}
          />
          {wizardState.step === "CONFIRM_CAMPAIGN_DETAILS" && (
            <ConfirmCampaignDetailsForm />
          )}
          {wizardState.step === "UPLOAD_SALES_DATA" && <UploadSalesData />}
          {wizardState.step === "CONFIRM_LOCATION_LIST" && (
            <ConfirmLocationList />
          )}
        </Box>
      )}
    </Box>
  );
}
