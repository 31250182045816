import React from "react";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import Danger from "@material-ui/icons/ReportProblemRounded";
import { makeStyles } from "@material-ui/core/styles";

import { useTrackerContext } from "context/roiTracker";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { Paper } from "atoms";
import {
  CampaignDetailsList,
  DownloadStoreListButton,
  SalesDataChart,
  StoreListPlanAnalysis,
} from "atoms/campaign";

import OverrideControlListModal from "./OverrideControlListModal";
import RedoTestControlModal from "./RedoTestControlModal";

const useStyles = makeStyles((theme) => ({
  tooltipPaper: {
    backgroundColor: theme.palette.background.default,
  },
  tooltipIcon: {
    marginLeft: 10,
    marginRight: 10,
  },
  tooltipBoldText: {
    marginRight: 10,
    fontWeight: 700,
  },
  blackButton: {
    // this is the only place this color seems to appear...
    backgroundColor: "#2C3349",
    color: "#FFF",
  },
}));

export default function GeneralCampaignDetailsPage() {
  const { trackerUtils } = useTrackerContext();
  const { campaignDetailsState } = useCampaignDetailsContext();
  const classes = useStyles();

  const [overrideStoreListModalOpen, setOverrideStoreListModalOpen] =
    React.useState(false);
  const [redoTestControlModalOpen, setRedoTestControlModalOpen] =
    React.useState(false);

  return (
    <>
      <OverrideControlListModal
        open={overrideStoreListModalOpen}
        setOpen={setOverrideStoreListModalOpen}
      />
      <RedoTestControlModal
        open={redoTestControlModalOpen}
        setOpen={setRedoTestControlModalOpen}
      />
      <Box p={5} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" width="100%" mb={3}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={trackerUtils.exitWizard}
          >
            Exit
          </Button>
          <Box display="flex">
            <Box mr={3}>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => {
                  setRedoTestControlModalOpen(true);
                }}
              >
                Redo Test Control
              </Button>
            </Box>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => {
                setOverrideStoreListModalOpen(true);
              }}
            >
              Override Control List
            </Button>
          </Box>
        </Box>
        <Box mb={5}>
          <Divider variant="middle" />
        </Box>
        <Box alignSelf="center">
          <Typography variant="h3">Test Control Details</Typography>
          <Box my={5} display="flex">
            <Box mr={3}>
              <CampaignDetailsList />
            </Box>
            {campaignDetailsState.salesData && <StoreListPlanAnalysis />}
          </Box>
          <Box mb={3}>
            <Typography variant="h3">
              Test Control Details Comparison
            </Typography>
          </Box>
          {campaignDetailsState.salesData && (
            <Box height={290} py={3}>
              <SalesDataChart height={263} initialDataSet="units" />
            </Box>
          )}
        </Box>
        <Paper mt={3} className={classes.tooltipPaper} width="100%" p={3}>
          <Box display="flex" alignItems="flex-start">
            <Danger fontSize="small" className={classes.tooltipIcon} />
            <Box>
              <Box mb={2}>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.tooltipBoldText}
                >
                  Tool Tip:
                </Typography>
                <Typography variant="body1" component="span">
                  Forgot to download the plan to give to your GENESIS Account
                  Manager for the media flight?
                </Typography>
              </Box>
              <DownloadStoreListButton
                variant="contained"
                size="large"
                className={classes.blackButton}
              >
                Download
              </DownloadStoreListButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
