export const initialState = {
  campaigns: {},
  // Sorted arrays of campaign ids
  active: [],
  completed: [],
  upcoming: [],
  campaignsChecked: false,
};

export function reducer(currentState, action) {
  switch (action.type) {
    case "SET_CAMPAIGNS": {
      return {
        ...currentState,
        campaigns: action.payload.campaigns,
        active: action.payload.active,
        completed: action.payload.complete,
        upcoming: action.payload.upcoming,
      };
    }
    case "SET_CAMPAIGNS_CHECKED": {
      return {
        ...currentState,
        campaignsChecked: action.payload,
      };
    }
    default:
      throw new Error(
        `CampaignsProvider dispatch received unhandled dispatch action type: ${action.type}`
      );
  }
}
