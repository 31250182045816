import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import mapDataUS from "@highcharts/map-collection/countries/us/us-all.geo.json";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useDisplay } from "utils/theme";
import { Paper } from "atoms";
import { titleCase } from "utils/formatters";

import StatesTable from "./StatesTable";

function formatTooltip(
  tooltip,
  stateCode = this.point.code,
  value = this.point.value,
  series = this.series
) {
  return `<b>${stateCode}</b><br/>${series.name}: <b>${value}</b>`;
}

highchartsMap(Highcharts);

export default function GeographyChart({ metric, data, color }) {
  const display = useDisplay();
  const height = display.widescreen ? 733 : display.mobile ? "100%" : 526; // vary for widescreen and mobile
  return (
    <Paper p={2} flexGrow={1} height={height} overflow="auto">
      <Typography variant="h3">Geography</Typography>
      <Typography variant="subtitle2">{titleCase(metric)} By State</Typography>
      <Box mt={2} display="flex" flexDirection="column" alignItems="center">
        <HighchartsReact
          constructorType={"mapChart"}
          highcharts={Highcharts}
          options={{
            chart: {
              map: "countries/us/us-all",
              width: display.widescreen
                ? 373
                : display.mobile && !display.tablet
                ? 410
                : 271,
              height: display.widescreen
                ? 184
                : display.mobile && !display.tablet
                ? 410
                : 134,
              events: {
                // force an extra reflow on load to resolve overflow issues
                load() {
                  setTimeout(this?.reflow?.bind(this), 0);
                },
              },
              style: {
                fontFamily: "Roboto",
              },
            },
            title: {
              text: null,
            },
            colorAxis: {
              min: 0,
              type: "linear",
              minColor: "#FFF",
              maxColor: color,
            },
            series: [
              {
                mapData: mapDataUS,
                data: data.map(([state, value]) => ({
                  code: state,
                  value,
                })),
                joinBy: ["postal-code", "code"],
                dataLabels: {
                  enabled: false,
                  color: "#FFFFFF",
                  format: "{point.code}",
                },
                name: metric,
                tooltip: {
                  pointFormat: "{point.code}: {point.value}",
                },
              },
            ],
            tooltip: { formatter: formatTooltip },
            legend: { enabled: false },
            credits: { enabled: false },
            exporting: { enabled: false },
          }}
        />
        <StatesTable metric={metric} data={data} />
      </Box>
    </Paper>
  );
}
