import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { setISOWeek, getISOWeeksInYear } from "date-fns";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { shortDate } from "utils/formatters";

const useStyles = makeStyles({
  buttonText: {
    fontWeight: "inherit",
  },
});

export default function SalesDataChart({ height, initialDataSet }) {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const {
    sales_data_start_week,
    sales_data_start_year,
    media_flight_week_indexes,
    pre_period_week_indexes,
    dollar_sums,
    unit_sums,
  } = campaignDetailsState.salesData || {};

  const [dataset, setDataset] = React.useState(initialDataSet);

  function toggleDataset() {
    if (dataset === "dollars") {
      setDataset("units");
    } else {
      setDataset("dollars");
    }
  }

  function chartIndexToDateString(index) {
    let year = sales_data_start_year;
    let week = index;
    let numWeeksInYear = getISOWeeksInYear(new Date(year, 1, 1));
    while (week > numWeeksInYear) {
      year += 1;
      week -= numWeeksInYear;
      numWeeksInYear = getISOWeeksInYear(new Date(year, 1, 1));
    }
    return shortDate(setISOWeek(new Date(year, 1, 1), week));
  }

  const classes = useStyles();
  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box alignSelf="flex-end" mr={3}>
        <Button
          className={classes.buttonText}
          size="small"
          onClick={toggleDataset}
        >
          {dataset === "dollars" ? "Show Units" : "Show Dollars"}
        </Button>
      </Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            height,
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: null,
          },
          plotOptions: {
            series: {
              pointStart: Number(sales_data_start_week),
            },
          },
          tooltip: {
            shared: true,
            formatter: function () {
              // taken from example at https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/tooltip/formatter-shared/
              return this.points.reduce(function (s, point) {
                return (
                  s +
                  "<br/>" +
                  point.series.name +
                  ": " +
                  Number(point.y).toFixed(2)
                );
              }, "<b>" + chartIndexToDateString(this.x) + "</b>");
            },
          },
          yAxis: {
            title: {
              text: dataset === "dollars" ? "Dollars" : "Units",
            },
            gridLineWidth: 0,
          },
          xAxis: {
            type: "linear",
            gridLineWidth: "1px",
            labels: {
              formatter: function () {
                // `this.value` is the index of the column.
                return chartIndexToDateString(this.value);
              },
            },
            plotBands: [
              pre_period_week_indexes && {
                from: pre_period_week_indexes[0],
                to: pre_period_week_indexes[1],
                color: {
                  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                  stops: [
                    [0, "rgba(255, 255, 255, 0.73)"],
                    // This is the result of `theme.palette.primary.main` at 73% opacity
                    [1, "rgba(124, 170, 255, 0.73)"],
                  ],
                },
                label: {
                  useHTML: true,
                  text: "<div>Pre-period</div>",
                  rotation: 90,
                  textAlign: "left",
                },
              },
              media_flight_week_indexes && {
                from: media_flight_week_indexes[0],
                to: media_flight_week_indexes[1],
                color: {
                  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                  stops: [
                    [0, "rgba(255, 255, 255, 0.73)"],
                    // This is the result of `theme.palette.secondary.main` at 73% opacity
                    [1, "rgba(43, 208, 144, 0.73)"],
                  ],
                },
                label: {
                  useHTML: true,
                  text: "<div>Media Flight</div>",
                  rotation: 90,
                  textAlign: "left",
                },
              },
              media_flight_week_indexes && {
                from: media_flight_week_indexes[1],
                to: media_flight_week_indexes[1] + 2,
                color: {
                  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                  stops: [
                    [0, "rgba(255, 255, 255, 0.45)"],
                    // This is the result of `theme.palette.secondary.main` at 50% opacity
                    [1, "rgba(43, 208, 144, 0.45)"],
                  ],
                },
                label: {
                  useHTML: true,
                  text: "<div>2 Weeks Post</div>",
                  rotation: 90,
                  textAlign: "left",
                },
              },
              // Filter out nulls where the sales data doesn't exist yet.
            ].filter((el) => el),
          },
          series: [
            {
              name: "Test",
              // Workaround for Highcharts bug where they are mutating the values passed down.
              data:
                dataset === "dollars"
                  ? [...dollar_sums.test]
                  : [...unit_sums.test],
            },
            {
              name: "Control",
              // Workaround for Highcharts bug where they are mutating the values passed down.
              data:
                dataset === "dollars"
                  ? [...dollar_sums.control]
                  : [...unit_sums.control],
            },
          ],
        }}
      />
    </Box>
  );
}
