import React from "react";
import { useRouteMatch, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MUIMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DraftsIcon from "@material-ui/icons/Drafts";

import { useAuthContext } from "context/auth";
import { logout } from "context/auth/actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 5,
  },
  active: {
    color: "#fff",
  },
  inactive: {
    color: "#7E84A3",
  },
}));

export default function Menu({ anchorEl, handleClose }) {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { authDispatch } = useAuthContext();
  return (
    <MUIMenu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box p={2}>
        <MenuItem
          component={Link}
          to="/"
          className={path === "/" ? classes.active : classes.inactive}
          onClick={handleClose}
        >
          <ListItemIcon>
            <DraftsIcon
              className={path === "/" ? classes.active : classes.inactive}
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText primary="My Campaigns" />
        </MenuItem>
        <MenuItem
          className={classes.inactive}
          onClick={() => {
            handleClose();
            authDispatch(logout);
          }}
        >
          <ListItemIcon>
            <LogoutIcon className={classes.inactive} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </MenuItem>
      </Box>
    </MUIMenu>
  );
}
