import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "./Modal";
import Paper from "./Paper";

const useStyles = makeStyles({
  text: {
    fontSize: "16px",
    marginBottom: 20,
  },
});

export default function ConfirmModal({
  open,
  setOpen,
  onConfirm,
  text,
  ariaLabel,
}) {
  const classes = useStyles();

  const paragraphs = Array.isArray(text) ? text : [text];
  return (
    <Modal label={ariaLabel} open={open} setOpen={setOpen}>
      <Paper width={511} p={5} px={10} display="flex" flexDirection="column">
        {paragraphs.map((paragraph) => (
          <React.Fragment key={paragraph}>
            <Typography variant="body1" className={classes.text}>
              {paragraph}
            </Typography>
          </React.Fragment>
        ))}
        <Box width="100%" display="flex" justifyContent="space-between" mt={3}>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={onConfirm}
          >
            Continue
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
