export const initialState = {
  user: null,
  sessionChecked: false,
};

export function reducer(currentState, action) {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...currentState,
        user: action.payload,
      };
    }
    case "CLEAR_USER": {
      return {
        ...currentState,
        user: null,
      };
    }
    case "SET_SESSION_CHECKED": {
      return {
        ...currentState,
        sessionChecked: action.payload,
      };
    }
    default:
      throw new Error(
        `AuthProvider dispatch received unhandled dispatch action type: ${action.type}`
      );
  }
}
