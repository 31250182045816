import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  bold: {
    fontWeight: 800,
    letterSpacing: "0.09px",
  },
});

export default function LabeledDataPair({ name, value }) {
  const classes = useStyles();
  return (
    <Box mb={1}>
      <Typography variant="body1" component="span" className={classes.bold}>
        {name}:{" "}
      </Typography>
      <Typography variant="body1" component="span">
        {value}
      </Typography>
    </Box>
  );
}
