import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { formatCurrency } from "utils/formatters";
import { makeStyles } from "@material-ui/core/styles";

import LabeledDataPair from "./LabeledDataPair";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `${theme.palette.text.secondary} solid 1px`,
    borderRadius: 8,
  },
  titleTextOverlapWrapper: {
    backgroundColor: "#FFF",
  },
  titleText: {
    color: theme.palette.text.secondary,
  },
  list: {
    // Takes padding from the parent
    paddingLeft: 0,
  },
}));

export default function CampaignDetailsList() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const campaignDetails = campaignDetailsState.campaignDetails;
  const classes = useStyles();
  return (
    <Box
      position="relative"
      className={classes.root}
      width={306}
      mt={3}
      px={3}
      py={1}
    >
      <Box
        position="absolute"
        width={168}
        top={-9}
        left={306 / 5}
        className={classes.titleTextOverlapWrapper}
      >
        <Typography align="center" className={classes.titleText} variant="h3">
          Campaign Details
        </Typography>
      </Box>
      <ul className={classes.list}>
        <LabeledDataPair name="Campaign Name" value={campaignDetails.name} />
        <LabeledDataPair
          name="Budget"
          value={formatCurrency(campaignDetails.budget)}
        />
        <LabeledDataPair
          name="Media Flight Start"
          value={campaignDetails.media_flight_start_at}
        />
        <LabeledDataPair
          name="Media Flight End"
          value={campaignDetails.media_flight_end_at}
        />
        <LabeledDataPair
          name="Desired Test Stores"
          value={campaignDetails.desired_loc_count}
        />
        <LabeledDataPair
          name="Media Saturation ($/Store/Week)"
          value={formatCurrency(campaignDetails.media_saturation)}
        />
      </ul>
    </Box>
  );
}
