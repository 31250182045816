import { postSalesData as apiCall } from "utils/api/campaigns";

export default function postSalesData(salesDataFile, columnMap, bypassML) {
  return async function action(campaignDetailsDispatch, campaignDetailsState) {
    const [response, error] = await apiCall(
      campaignDetailsState.campaignDetails.id,
      salesDataFile,
      columnMap,
      bypassML
    );

    if (error) {
      throw new Error(error);
    } else {
      campaignDetailsDispatch({
        type: "SET_CAMPAIGN",
        payload: response.data,
      });
    }
  };
}
