import React from "react";
import {
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Paper } from "atoms";
import { useTotalOrNoMeasurableLift } from "utils/formatters";

const TableCell = withStyles((theme) => ({
  root: {
    borderBottom: 0,
    color: theme.palette.text.primary,
    fontWeight: 500,
    verticalAlign: "middle",
  },
  head: {
    fontWeight: "700 !important",
  },
}))(MUITableCell);

export default function PerformanceTotalsTable({ totals }) {
  const totalOrNoMeasurableLift = useTotalOrNoMeasurableLift();
  return (
    <Paper my={2} py={1} px={3} width="100%">
      <TableContainer>
        <Table aria-label="Total Performance" size="small">
          <TableBody>
            <TableRow>
              <TableCell width="9%"></TableCell>
              <TableCell width="9%"></TableCell>
              <TableCell
                variant="head"
                colSpan={3}
                width="23%"
                component="th"
                scope="row"
                align="right"
              >
                TOTAL:
              </TableCell>
              <TableCell align="right" width="10%">
                {totalOrNoMeasurableLift(totals.dollars.lift_percent, "%")}
              </TableCell>
              <TableCell align="right" width="10%">
                {totalOrNoMeasurableLift(totals.dollars.lift_total, "$")}
              </TableCell>
              <TableCell align="right" width="7%">
                {totalOrNoMeasurableLift(
                  totals.dollars.lift_per_loc_per_week,
                  "$"
                )}
              </TableCell>
              <TableCell align="right" width="7%">
                {totalOrNoMeasurableLift(totals.units.lift_total)}
              </TableCell>
              <TableCell align="right" width="10%">
                {totalOrNoMeasurableLift(totals.units.lift_per_loc_per_week)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
