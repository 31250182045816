export default function selectMetricType(metricType) {
  return async function selectMetricTypeAction(metricsDispatch) {
    metricsDispatch({
      type: "SET_LOADING",
      payload: true,
    });

    metricsDispatch({
      type: "SET_METRIC_TYPE",
      payload: metricType,
    });
    metricsDispatch({
      type: "SET_LOADING",
      payload: false,
    });
  };
}
