import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { phonePile } from "img/phonePile";
import { genesisLogo } from "img/genesisLogo";

import { login } from "context/auth/actions";
import { useAuthContext } from "context/auth";
import { useDisplay } from "utils/theme";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "hidden",
  },
  notchedOutline: {
    borderColor: "#FFF",
  },
  select: {
    margin: "0.65rem 0",
    width: "100%",
    color: "#FFF",
    fontSize: "16px",
    "& .MuiFormLabel-root": {
      color: "#FFF",
      fontSize: "16px",
    },
    "& .MuiInputBase-input": {
      color: "#FFF",
      fontSize: "16px",
    },
  },
  bigText: {
    fontFamily: "Roboto, Bold",
    lineHeight: "40px",
    fontSize: "42px",
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { authDispatch } = useAuthContext();
  const display = useDisplay();

  function handleSubmit(e) {
    e.preventDefault();
    login(username, password, authDispatch);
  }

  return (
    <Box
      className={classes.root}
      alignItems="center"
      justifyContent="space-between"
      display="flex"
      height="100%"
      width="100%"
    >
      <Box
        alignItems="center"
        justifyContent="center"
        width="50%"
        height="100%"
        display={display.desktop ? "flex" : "none"}
      >
        <img
          height="100%"
          src={phonePile.src}
          aria-label={phonePile.ariaLabel}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={display.desktop ? "50%" : "100%"}
        height="100%"
      >
        <Box
          height="100%"
          maxWidth={540}
          justifyContent="center"
          alignItems="stretch"
          display="flex"
          flexDirection="column"
          px={2}
        >
          <Typography
            variant="h1"
            component={Box}
            mb={8}
            align="center"
            className={classes.bigText}
          >
            Welcome to Your
            <br />
            Campaign Dashboard!
          </Typography>
          <Box maxWidth={470}>
            <form onSubmit={handleSubmit}>
              <TextField
                className={classes.select}
                InputProps={{
                  classes: { notchedOutline: classes.notchedOutline },
                }}
                variant="outlined"
                margin="normal"
                required
                id="username"
                label="E-mail"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <TextField
                className={classes.select}
                InputProps={{
                  classes: { notchedOutline: classes.notchedOutline },
                }}
                variant="outlined"
                margin="normal"
                required
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Box mt={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  SIGN IN
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Box mb={3} display="flex" flexDirection="column" alignItems="center">
          <img
            src={genesisLogo.src}
            height={genesisLogo.height / 10}
            width={genesisLogo.width / 10}
            aria-label={genesisLogo.ariaLabel}
          />
          <Typography variant="caption" component={Box} m={1}>
            Copyright &copy; Genesis {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
