import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Paper } from "atoms";
import { useMetricsContext } from "context/metrics";
import { selectMetricType } from "context/metrics/actions";
import { titleCase } from "utils/formatters";
import { getThemeColor, useDisplay } from "utils/theme";

import METRICS_TOTALS_MAP from "../../../metricsTotalsMap";

import SmallChart from "./SmallChart";

const useStyles = makeStyles((theme) => ({
  pointer: () => ({
    cursor: "pointer",
  }),
  selected: ({ metricType }) => ({
    backgroundColor: getThemeColor(metricType, theme),
    color: "#FFF",
    textAlign: "center",
  }),
  text: {
    fontWeight: 500,
    fontSize: 16,
    whiteSpace: "nowrap",
  },
  topMetrics: {
    "& > :nth-child(2)": {
      margin: `0 ${theme.spacing(2)}px`,
    },
  },
  bigText: {
    fontWeight: 900,
    fontSize: 28,
  },
}));

export default function SelectMetricType() {
  const { metricsState, metricsDispatch, metricsUtils } = useMetricsContext();
  const { metricType } = metricsState; // The currently selected metric type, to compare against metric prop.
  const classes = useStyles({ metricType });
  const display = useDisplay();

  return (
    <Box display="flex" mt={1} className={classes.topMetrics}>
      {metricsUtils.availableMetrics.map((metric) => (
        <React.Fragment key={metric}>
          {metric !== metricType ? (
            <Paper
              p={1}
              px={2}
              className={classes.pointer}
              onClick={() => {
                metricsDispatch(selectMetricType(metric));
              }}
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              width="33%"
              height={display.widescreen ? 112 : 79}
            >
              <Box>
                <Typography
                  className={clsx(classes.text, classes.bigText)}
                  component="span"
                  variant="h1"
                  color="textSecondary"
                >
                  {Number(
                    metricsState.metricDetails.totals[
                      METRICS_TOTALS_MAP.get(metric)
                    ]
                  ).toLocaleString("en-US")}
                </Typography>
                <Typography
                  className={classes.text}
                  variant="h4"
                  component="h3"
                >
                  Total {titleCase(metric)}
                </Typography>
              </Box>
              <SmallChart metric={metric} />
            </Paper>
          ) : (
            <Paper
              p={1}
              className={classes.selected}
              onClick={() => {
                metricsDispatch(selectMetricType(metric));
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="33%"
              height={display.widescreen ? 112 : 79}
            >
              <Box>
                <Typography component="span" variant="h1" color="inherit">
                  {Number(
                    metricsState.metricDetails.totals[
                      METRICS_TOTALS_MAP.get(metric)
                    ]
                  ).toLocaleString("en-US")}
                </Typography>
                <Typography
                  className={classes.text}
                  variant="h4"
                  component="h3"
                  color="inherit"
                  align="center"
                >
                  Total {titleCase(metric)}
                </Typography>
              </Box>
            </Paper>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
