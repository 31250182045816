import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Paper } from "atoms";
import { titleCase } from "utils/formatters";
import { useDisplay } from "utils/theme";
import { useTheme } from "@material-ui/core";

function formatTooltip(tooltip, x = this.x, y = this.y, series = this.series) {
  return `<b>${series.name} "${x}"</b>: ${y}`;
}

export default function GenderChart({ metric, data, color, genderOptions }) {
  const display = useDisplay();
  const theme = useTheme();
  return (
    <Paper
      p={2}
      flexShrink={1}
      flexGrow={1}
      height={display.widescreen ? 290 : 213}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h3">Gender</Typography>
      <Typography variant="subtitle2">{titleCase(metric)} By Gender</Typography>
      <Box overflow="hidden">
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { height: "100%" } }}
          options={{
            chart: {
              type: "bar",
              events: {
                // force an extra reflow on load to resolve overflow issues
                load() {
                  setTimeout(this?.reflow?.bind(this), 0);
                },
              },
              style: {
                fontFamily: "Roboto",
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                dataLabels: {
                  format: "{y}%",
                  color: theme.palette.text.primary,
                  style: { fontWeight: 400 },
                },
              },
            },
            title: {
              text: null,
            },
            xAxis: {
              labels: {
                style: { color: theme.palette.text.secondary },
              },
              categories: genderOptions,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: null,
              visible: false,
            },
            series: [
              {
                name: "Gender",
                data,
                color,
                dataLabels: {
                  enabled: true,
                },
                // pointWidth: 43,
              },
            ],
            tooltip: { formatter: formatTooltip },
            legend: { enabled: false },
            credits: { enabled: false },
            exporting: { enabled: false },
          }}
        />
      </Box>
    </Paper>
  );
}
