import { parseDate } from "./api";

const today = new Date();
today.setHours(0, 0, 0, 0);
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0, 0, 0, 0);

export default function getCampaignStatus(
  mediaFlightStartAt,
  mediaFlightEndAt
) {
  const start = parseDate(mediaFlightStartAt);
  const end = parseDate(mediaFlightEndAt);
  if (
    (end >= today && end <= tomorrow) ||
    (start >= today && start <= tomorrow)
  ) {
    // If it starts or ends today, it's active.
    return "ACTIVE";
  } else if (end < today) {
    // If it ends before today, it's past
    return "COMPLETE";
  } else if (start < today) {
    // If it starts before today, it's active (because we know it's not past)
    return "ACTIVE";
  } else {
    // Otherwise, it's upcoming
    return "UPCOMING";
  }
}
