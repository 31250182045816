import setMetricDetails from "./setMetricDetails";

export default function selectMediaLineItem(i) {
  return async function selectMediaLineItemAction(
    metricsDispatch,
    metricsState
  ) {
    metricsDispatch({
      type: "SET_LOADING",
      payload: true,
    });

    metricsDispatch({
      type: "SET_SELECTED_MEDIA_LINE_ITEM",
      payload: i,
    });
    const { google_sheet_id } = metricsState.mediaLineItemsList[i];
    await metricsDispatch(setMetricDetails(google_sheet_id));

    metricsDispatch({
      type: "SET_LOADING",
      payload: false,
    });
  };
}
