import React, { useContext } from "react";
// TODO: Make a nicer loading screen while auth is checked.
import CircularProgress from "@material-ui/core/CircularProgress";

import { useCustomReducer, useMount } from "utils";
import { checkSession } from "./actions";
import { reducer, initialState } from "./reducer";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [authState, authDispatch] = useCustomReducer(reducer, initialState);
  useMount(() => {
    checkSession(authDispatch);
  });

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {authState.sessionChecked ? children : <CircularProgress />}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
}
