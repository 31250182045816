import React, { useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";

import { Backdrop } from "atoms";
import { useCustomReducer } from "utils";
import { useCampaignDetailsContext } from "context/campaignDetails";

import { getmediaLineItemsList } from "./actions";
import { reducer, initialState } from "./reducer";
import { Box } from "@material-ui/core";

const MetricsContext = React.createContext();

export function MetricsProvider({ children }) {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const { click_data_available_for_media_line_items, id: campaignId } =
    campaignDetailsState.campaignDetails;
  const [metricsState, metricsDispatch] = useCustomReducer(
    reducer,
    initialState
  );
  useEffect(() => {
    metricsDispatch(
      getmediaLineItemsList(
        campaignId,
        click_data_available_for_media_line_items
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return (
    <MetricsContext.Provider value={{ metricsState, metricsDispatch }}>
      <Backdrop open={metricsState.loading} />
      {metricsState.metricDetails
        ? children
        : !metricsState.loading && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="30%"
            >
              <Typography variant="h1">
                No Media Line Items for this Campaign.
              </Typography>
              <Typography variant="subtitle1">
                Contact your administrator for help.
              </Typography>
            </Box>
          )}
    </MetricsContext.Provider>
  );
}

export function useMetricsContext() {
  const context = useContext(MetricsContext);
  if (context === undefined) {
    throw new Error("useMetricsContext must be used within an MetricsProvider");
  }

  const { campaignDetailsState } = useCampaignDetailsContext();
  const { click_data_available_for_media_line_items } =
    campaignDetailsState.campaignDetails;

  const availableMetrics = ["impressions", "activations", "clicks"].filter(
    (el) => {
      if (click_data_available_for_media_line_items) {
        return true;
      }
      return el !== "clicks";
    }
  );

  return {
    ...context,
    metricsUtils: {
      availableMetrics,
    },
  };
}
