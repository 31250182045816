import React from "react";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { useTrackerContext } from "context/roiTracker";
import { userDecidedToOverride } from "context/campaignDetails/actions";
import { ConfirmModal } from "atoms";

export default function OverrideControlListModal({ open, setOpen }) {
  const { trackerUtils } = useTrackerContext();
  const { campaignDetailsDispatch } = useCampaignDetailsContext();
  async function onConfirm() {
    trackerUtils.asyncCallbackThenSetView(
      async () => await campaignDetailsDispatch(userDecidedToOverride),
      "MID_CAMPAIGN_LANDING_PAGE"
    );
  }
  return (
    <ConfirmModal
      ariaLabel="Confirm Override Store List"
      open={open}
      setOpen={setOpen}
      text={[
        "Have your own Test/Control stores selected that you would prefer to use?",
        "To complete the Pre-campaign set up with your test and control store list, please send your store list breakdown to your genesis account representative. They will use this list to finalize your pre-campaign set up and as guidance for serving media.",
        "Click Continue to override the machine learning test control store list plan.",
      ]}
      onConfirm={onConfirm}
    />
  );
}
