import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { useAuthContext } from "context/auth";

import Menu from "./Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    borderRadius: "50%",
    margin: "auto 0",
    verticalAlign: "middle",
  },
  whiteText: {
    color: "#FFF",
  },
  logoBg: {
    backgroundColor: "#FFF",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const { authState } = useAuthContext();

  // See MUI "Menu" docs.
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="static" className={classes.root}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minHeight={70}
        >
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={openMenu}
            >
              <MenuIcon font="large" />
            </IconButton>
          </Toolbar>
          <Box display="flex" alignItems="center">
            {/* Company Name */}
            <Typography className={classes.whiteText} variant="h2">
              {authState.user.groups[0]?.name}
            </Typography>
            {/* Company Logo */}
            <Box className={classes.logoBg} mx={2}>
              <img
                src={authState.user.groups[0]?.company?.logo}
                aria-label={`${authState.user.groups[0]?.name} Logo`}
                width={40}
                className={classes.logo}
              />
            </Box>
          </Box>
        </Box>
      </AppBar>
      <Menu anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
}
