import React from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

export default function ProductDetails({
  handleChange,
  inputsState,
  handleTooltipClick,
}) {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" width="100%" px="82px">
      <Box width={380} mr="80px">
        <Box mb={3} display="flex" justifyContent="space-between">
          <TextField
            className={classes.fullWidth}
            variant="outlined"
            label="Feature UPCs (comma separated)"
            onChange={(e) => {
              handleChange("featureUpcs", e.target.value.split(","));
            }}
            value={inputsState.featureUpcs.join().trim()}
            required
          />
          <IconButton
            onClick={() => {
              handleTooltipClick("featureUpcs");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box mb={3} display="flex" justifyContent="space-between">
          <TextField
            className={classes.fullWidth}
            label="Halo UPCs (comma separated)"
            onChange={(e) => {
              handleChange("haloUpcs", e.target.value.split(","));
            }}
            value={inputsState.haloUpcs.join().trim()}
            variant="outlined"
          />
          <IconButton
            onClick={() => {
              handleTooltipClick("haloUpcs");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box mb={3} display="flex" justifyContent="space-between">
          <TextField
            className={classes.fullWidth}
            variant="outlined"
            label="Proxy UPCs (only if New)"
            onChange={(e) => {
              handleChange("proxyUpcs", e.target.value.split(","));
            }}
            value={inputsState.proxyUpcs.join().trim()}
            disabled={!inputsState.newItem}
          />
          <IconButton
            onClick={() => {
              handleTooltipClick("proxyUpcs");
            }}
          >
            <Help />
          </IconButton>
        </Box>
      </Box>
      <Box width={380}>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          mb={3}
        >
          <Box minWidth="30%" maxWidth="30%" display="flex" alignItems="center">
            <FormLabel component="legend">Item Type*</FormLabel>
          </Box>
          <Box width="60%">
            <RadioGroup
              value={inputsState.newItem}
              onChange={(e) => {
                handleChange(
                  "newItem",
                  e.target.value === "true" ? true : false
                );
              }}
              row
              required
            >
              <FormControlLabel
                value={true}
                label="New"
                labelPlacement="start"
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                value={false}
                label="Existing"
                labelPlacement="start"
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </Box>
          <IconButton
            onClick={() => {
              handleTooltipClick("newItem");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" width="100%" mb={3}>
          <Box minWidth="30%" maxWidth="30%" mr={2}>
            <FormLabel component="legend" id="other-tactics-executed-label">
              Other Tactics Executed in these Stores?*
            </FormLabel>
          </Box>
          <Box
            aria-labelledby="other-tactics-executed-label"
            display="flex"
            width="60%"
          >
            <Box mr={3}>
              <Button
                color={inputsState.otherTacticsExecuted ? "primary" : "default"}
                variant={
                  inputsState.otherTacticsExecuted ? "contained" : "outlined"
                }
                onClick={() => {
                  handleChange("otherTacticsExecuted", true);
                }}
                aria-label="Yes, Other Tactics Were Executed"
              >
                Yes
              </Button>
            </Box>
            <Button
              color={!inputsState.otherTacticsExecuted ? "primary" : "default"}
              variant={
                !inputsState.otherTacticsExecuted ? "contained" : "outlined"
              }
              onClick={() => {
                handleChange("otherTacticsExecuted", false);
              }}
              aria-label="No, Other Tactics Were Not Executed"
            >
              No
            </Button>
          </Box>
          <IconButton
            onClick={() => {
              handleTooltipClick("otherTacticsExecuted");
            }}
          >
            <Help />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" width="100%">
          <Box minWidth="30%" maxWidth="30%" mr={2}>
            <FormLabel component="legend" id="merchandising-label">
              In-store Display?*
            </FormLabel>
          </Box>
          <Box aria-labelledby="merchandising-label" display="flex" width="60%">
            <Box mr={3}>
              <Button
                color={inputsState.merchandising ? "primary" : "default"}
                variant={inputsState.merchandising ? "contained" : "outlined"}
                onClick={() => {
                  handleChange("merchandising", true);
                }}
                aria-label="Yes, In-store Display Planned"
              >
                Yes
              </Button>
            </Box>
            <Button
              color={!inputsState.merchandising ? "primary" : "default"}
              variant={!inputsState.merchandising ? "contained" : "outlined"}
              onClick={() => {
                handleChange("merchandising", false);
              }}
              aria-label="No, In-store Display Not Planned"
            >
              No
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
