import React from "react";
import { CSVLink } from "react-csv";
import { Button } from "@material-ui/core";

import { useCampaignDetailsContext } from "context/campaignDetails";

export default function DownloadStoreListButton({ children, ...buttonProps }) {
  const { campaignDetailsState } = useCampaignDetailsContext();

  return (
    <Button
      {...buttonProps}
      component={CSVLink}
      data={campaignDetailsState.campaignDetails.loc_status_csv}
      filename={`${campaignDetailsState.campaignDetails.name} Store List.csv`}
    >
      {children}
    </Button>
  );
}
