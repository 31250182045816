import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CheckCircle, WatchLater } from "@material-ui/icons";

import { useTrackerContext } from "context/roiTracker";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { Paper } from "atoms";
import {
  CampaignDatesBox,
  CampaignNamePageHeaderWithSubtitle,
} from "atoms/campaign";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: "20px",
    fontWeight: 500,
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  greyText: {
    color: theme.palette.text.secondary,
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.text.secondary}`,
  },
}));

export default function MidCampaignLandingPage() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const { trackerDispatch } = useTrackerContext();
  const classes = useStyles();
  const theme = useTheme();

  const needsOverride =
    campaignDetailsState.campaignDetails.roi_state === "NEEDS_OVERRIDE";

  function goToPostCampaignSetup() {
    trackerDispatch.setMainView("POST_CAMPAIGN_WIZARD");
  }

  function goToDetailsPage() {
    trackerDispatch.setMainView("GENERAL_CAMPAIGN_DETAILS_PAGE");
  }

  return (
    <Box width="100%" height="100%" display="flex" justifyContent="center">
      <Box mt={4}>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <CampaignNamePageHeaderWithSubtitle subtitle="Sales Lift Tracker" />
          <CampaignDatesBox />
        </Box>
        <Paper p={8} width={940} variant="outlined">
          <Typography align="center" variant="h2">
            Campaign Set-Up Progress
          </Typography>
          <Typography
            className={classes.subtitle}
            align="center"
            variant="subtitle2"
            gutterBottom
          >
            {needsOverride
              ? "You are almost there!"
              : "Now you are ready to analyze your post-campaign data!"}
          </Typography>
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="center"
              width={720}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={2}>
                  {needsOverride ? (
                    <WatchLater color="primary" fontSize="large" />
                  ) : (
                    <CheckCircle color="primary" fontSize="large" />
                  )}
                </Box>
                <Typography variant="h3" className={classes.blueText}>
                  PRE-CAMPAIGN DETAILS
                </Typography>
                <Box zIndex={3} mt={2} mb={1}>
                  <svg height="30" width={String(720 / 2)}>
                    <rect
                      x="0"
                      y="0"
                      rx="4"
                      ry="4"
                      width={String(720 / 2)}
                      height="8"
                      fill={theme.palette.primary.main}
                      strokeWidth={5}
                    />
                  </svg>
                </Box>
                <Box
                  px={3}
                  className={classes.rightBorder}
                  height={100}
                  display="flex"
                  alignItems="center"
                  mb={3}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    className={classes.greyText}
                  >
                    {needsOverride
                      ? "Ensure your data override file has been sent to your GENESIS Rep. Once they have uploaded the information, you will be able to: review or download Pre-campaign details and leverage data for post-campaign analysis."
                      : "You have successfully set up your campaign! Click below to review or edit Test and Control Store List Plan."}
                  </Typography>
                </Box>
                {!needsOverride && (
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={goToDetailsPage}
                  >
                    View Campaign Details
                  </Button>
                )}
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h3">POST-CAMPAIGN ANALYSIS</Typography>
                <Box zIndex={1} marginLeft="-5px" mt={2} mb={1}>
                  <svg height="30" width={String(720 / 2 + 5)}>
                    <rect
                      x="0"
                      y="0"
                      rx="4"
                      ry="4"
                      width={String(720 / 2 + 5)}
                      height="8"
                      fill={theme.palette.action.disabledBackground}
                      strokeWidth={5}
                    />
                  </svg>
                </Box>
                <Box
                  px={3}
                  height={100}
                  display="flex"
                  alignItems="center"
                  mb={3}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    className={classes.greyText}
                  >
                    2 weeks post campaign, retrieve the retailer sales data and
                    upload here. Our system will analyze the data.
                  </Typography>
                </Box>
                {!needsOverride && (
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={goToPostCampaignSetup}
                  >
                    Ready to Upload
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
