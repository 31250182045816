import { makeStyles } from "@material-ui/core/styles";

import { useMetricsContext } from "context/metrics";
import { selectMediaLineItem } from "context/metrics/actions";
import { Select } from "molecules/Form";

const useStyles = makeStyles({
  root: {
    minWidth: "100%",
  },
});

export default function SelectMediaLineItem() {
  const classes = useStyles();
  const { metricsState, metricsDispatch } = useMetricsContext();
  return (
    <Select
      className={classes.root}
      value={metricsState.selectedMediaLineItem}
      onChange={(e) => metricsDispatch(selectMediaLineItem(e.target.value))}
      options={metricsState.mediaLineItemsList.map((el, i) => ({
        value: i,
        label: el.name,
      }))}
    />
  );
}
