import { parseDate } from "utils/api";
import { getCampaignsList as apiCall } from "utils/api/campaigns";

const today = new Date();
today.setHours(0, 0, 0, 0);
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0, 0, 0, 0);

export default async function getCampaigns(campaignsDispatch) {
  const [response, error] = await apiCall();
  if (!error) {
    // 3 lists, sorted by start date.
    let upcoming = [
      //{id, date}
    ];
    let active = [];
    let complete = [];
    const campaigns = response.data.reduce((acc, el) => {
      el.media_flight_start_at = parseDate(el.media_flight_start_at);
      el.media_flight_end_at = parseDate(el.media_flight_end_at);
      acc[el.id] = el;

      if (
        (el.media_flight_end_at >= today &&
          el.media_flight_end_at <= tomorrow) ||
        (el.media_flight_start_at >= today &&
          el.media_flight_start_at <= tomorrow)
      ) {
        // If it starts or ends today, it's active.
        active.push({ id: el.id, date: el.media_flight_start_at });
        acc[el.id].status = "active";
      } else if (el.media_flight_end_at < today) {
        // If it ends before today, it's past
        complete.push({ id: el.id, date: el.media_flight_start_at });
        acc[el.id].status = "completed";
      } else if (el.media_flight_start_at < today) {
        // If it starts before today, it's active (because we know it's not past)
        active.push({ id: el.id, date: el.media_flight_start_at });
        acc[el.id].status = "active";
      } else {
        // Otherwise, it's upcoming
        upcoming.push({ id: el.id, date: el.media_flight_start_at });
        acc[el.id].status = "upcoming";
      }
      return acc;
    }, {});

    function sorter(a, b) {
      if (a.date === b.date) {
        return 0;
      }
      if (a.date < b.date) {
        return -1;
      }
      return 1;
    }
    active.sort(sorter);
    complete.sort(sorter);
    upcoming.sort(sorter);

    // Don't need the dates anymore
    active = active.map(({ id }) => id);
    complete = complete.map(({ id }) => id);
    upcoming = upcoming.map(({ id }) => id);

    campaignsDispatch({
      type: "SET_CAMPAIGNS",
      payload: {
        campaigns,
        active,
        complete,
        upcoming,
      },
    });
  }
  campaignsDispatch({
    type: "SET_CAMPAIGNS_CHECKED",
    payload: true,
  });
}
