import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

export default function PerformanceTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell width="9%">UPC</TableCell>
        <TableCell width="9%">Item Num</TableCell>
        <TableCell width="23%">Item Description</TableCell>
        <TableCell width="10%" align="right">
          Lift %
        </TableCell>
        <TableCell width="10%" align="right">
          Lift $
        </TableCell>
        <TableCell width="7%" align="right">
          Lift $<br />
          Per Store
          <br />
          Per Week
        </TableCell>
        <TableCell width="7%" align="right">
          Lift Units
        </TableCell>
        <TableCell width="10%" align="right">
          Lift Units
          <br />
          Per Store
          <br />
          Per Week
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
