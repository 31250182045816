import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import * as gradients from "theme/metricsGradients";

const US_STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

function sorter(a, b) {
  // Sort by the value, which is the second element of the array.
  // Sorts from highest to lowest
  if (a[1] > b[1]) {
    return -1;
  }
  if (a[1] < b[1]) {
    return 1;
  }
  return 0;
}

function makeRows(data, metric) {
  const total = data.reduce((acc, el) => acc + el[1], 0);
  // data is a React prop, and cannot be modified in place
  const sorted = [...data].sort(sorter);
  const upper = sorted?.[0]?.[1];
  return sorted.map(([shortState, val]) => {
    const percent = Math.floor((val / total) * 100);
    const gradientIndex = Math.floor((val / upper) * 100) - 1;
    return {
      state: US_STATES[shortState],
      percent,
      color: gradients[metric][gradientIndex],
    };
  });
}

const useStyles = makeStyles((theme) => ({
  leftBodyCell: { padding: "0 0 0 5%", height: "auto !important" },
  rightBodyCell: { padding: "0 10% 0 0", height: "auto !important" },
  noHighlight: {
    "& th": {
      backgroundColor: "transparent !important",
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
  },
}));

// data is an array of [state, value] arrays
// ie [["OH", 123], ["NH", 321]]
export default function StatesTable({ metric, data }) {
  const classes = useStyles();
  return (
    <TableContainer component={Box} mt={1}>
      <Table
        aria-label={`${metric} by state`}
        stickyHeader
        className={classes.noHighlight}
      >
        <TableHead>
          <TableRow>
            <TableCell>State</TableCell>
            <TableCell align="right">Percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {makeRows(data, metric).map(({ state, percent, color }) => (
            <TableRow key={state} style={{ height: 35 }}>
              <TableCell className={classes.leftBodyCell}>
                <Box component="span" mr={3}>
                  <svg
                    width="11"
                    height="11"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" fill={color} />
                  </svg>
                </Box>
                <Typography component="span">{state}</Typography>
              </TableCell>
              <TableCell className={classes.rightBodyCell} align="right">
                <Typography component="span">{percent}%</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
