import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Paper } from "atoms";
import CampaignsList from "CampaignsList";
import { useDisplay } from "utils/theme";
import { useDashboardContext } from "context/dashboardLayout";

import WorkingOnWizardCampaignItem from "./WorkingOnWizardCampaignItem";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  root: ({ display, dashboardState }) => {
    let width;
    if (display.widescreen) {
      width = dashboardState.narrowSideNav ? 275 : 450;
    } else {
      width = dashboardState.narrowSideNav ? 275 : 348;
    }
    // Has a different bg color for contrast with the main display.
    return {
      border: `2px solid ${theme.palette.grey[300]}`,
      boxShadow: "9px 9px 40px #3754AA1A",
      backgroundColor: "#F5F6FA",
      minWidth: width,
      maxWidth: width,
      height: "100%",
      zIndex: 3,
    };
  },
  navHeader: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function SideNav() {
  const { dashboardState } = useDashboardContext();
  const display = useDisplay();
  const classes = useStyles({ display, dashboardState });
  return (
    <Paper
      className={classes.root}
      p={1}
      square
      display="flex"
      flexDirection="column"
    >
      <Header />
      <Typography component={Box} m={2} my={1.5} mx={2} variant="h3">
        My Campaigns
      </Typography>
      {dashboardState.narrowSideNav ? (
        <WorkingOnWizardCampaignItem />
      ) : (
        <CampaignsList />
      )}
    </Paper>
  );
}
