import { getmediaLineItemsList as apiCall } from "utils/api/campaigns";
import setMetricDetails from "./setMetricDetails";

export default function getmediaLineItemsList(campaignId, hasClickData) {
  return async function getmediaLineItemsListAction(metricsDispatch) {
    metricsDispatch({
      type: "SET_LOADING",
      payload: true,
    });
    const [response, error] = await apiCall(campaignId);
    if (error) {
      metricsDispatch({
        type: "SET_LOADING",
        payload: false,
      });
      return;
    }
    metricsDispatch({
      type: "SET_MEDIA_LINE_ITEMS_LIST",
      payload: response.data,
    });

    if (response.data.length > 0) {
      const { google_sheet_id } = response.data[0];
      await metricsDispatch(setMetricDetails(google_sheet_id, hasClickData));
    }

    metricsDispatch({
      type: "SET_LOADING",
      payload: false,
    });
  };
}
