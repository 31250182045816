import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { usePostCampaignWizardContext } from "context/postCampaignWizard";
import { Paper } from "atoms";
import { like } from "img";

const useStyles = makeStyles((theme) => ({
  smallGutterBottom: {
    marginBottom: theme.spacing(3),
  },
}));

export default function FullySetUpConfirmationPage() {
  const { wizardUtils } = usePostCampaignWizardContext();
  const classes = useStyles();

  return (
    <Box height="100%" display="flex" alignItems="center">
      <Paper
        variant="outlined"
        height={550}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          width={308}
        >
          <Box mb={3}>
            <img src={like.src} aria-label={like.ariaLabel} width={121} />
          </Box>
          <Box mb={3}>
            <Typography component="h2" variant="h3" align="center">
              Congratulations, <br />
              Your Campaign is Fully Set Up!
            </Typography>
          </Box>
          <Typography
            align="center"
            variant="body1"
            className={classes.smallGutterBottom}
          >
            Now that you have completed the Post-campaign set-up you can review
            your campaign analytics at any time.
          </Typography>
          <Box mt={2}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={wizardUtils.finishWizard}
            >
              View Results
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
