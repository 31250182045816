import axios from "axios";

// The apiHandler always returns a 2-length array with [response, errorMessage]

const apiHandler = axios.create({
  mode: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
});

function errorHandler(error) {
  const { response } = error;
  const { data, status } = response;

  if (status >= 500) {
    return [
      response,
      "Internal Server Error. Technical Details: " +
        JSON.stringify(data, null, 2),
    ];
  }

  if (status === 400) {
    if (Array.isArray(data)) {
      // Django Rest usually returns an array for 400 validation errors.
      return [response, data.join(", ")];
    } else {
      // This is an edge case. Sometimes it sends an object.
      // TODO: Research what that object would look like and parse it instead.
      return [response, JSON.stringify(data)];
    }
  }
  return [response, data.detail];
}

apiHandler.interceptors.response.use(
  (response) => [response, undefined],
  errorHandler
);
apiHandler.defaults.baseURL = "/api/";
apiHandler.defaults.xsrfHeaderName = "X-CSRFTOKEN";
apiHandler.defaults.xsrfCookieName = "csrftoken";

export default apiHandler;
