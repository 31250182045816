import React from "react";
import clsx from "clsx";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import Danger from "@material-ui/icons/ReportProblemRounded";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { makeStyles } from "@material-ui/core/styles";

import { Paper } from "atoms";
import { usePostCampaignWizardContext } from "context/postCampaignWizard";
import { useCampaignDetailsContext } from "context/campaignDetails";
import { useTrackerContext } from "context/roiTracker";
import { postSalesData } from "context/campaignDetails/actions";
import { EditFieldNamesModal } from "atoms/campaign";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.text.primary,
  },
  tooltipPaper: {
    backgroundColor: theme.palette.background.default,
  },
  tooltipIcon: {
    marginLeft: 10,
    marginRight: 10,
  },
  tooltipText: {
    marginRight: 10,
  },
  helpButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  greyButton: {
    backgroundColor: theme.palette.text.secondary,
  },
  fileUploadField: {
    width: "60%",
  },
  smallGutterBottom: {
    marginBottom: 15,
  },
  boldText: {
    fontWeight: 700,
  },
  outlinedButtonOnGray: {
    backgroundColor: "#FFF",
  },
}));

export default function UploadSalesData() {
  const { campaignDetailsDispatch, campaignDetailsState } =
    useCampaignDetailsContext();
  const { wizardUtils } = usePostCampaignWizardContext();
  const { trackerUtils } = useTrackerContext();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [file, setFile] = React.useState();
  const [fieldNames, setFieldNames] = React.useState({});
  const [displayHelpText, setDisplayHelpText] = React.useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    wizardUtils.asyncCallbackThenStep(async () => {
      await campaignDetailsDispatch(postSalesData(file, fieldNames));
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Paper
            variant="outlined"
            p={5}
            display="flex"
            flexDirection="column"
            mb={3}
            overflow="auto"
            height={displayHelpText ? 500 : 432}
          >
            <Typography variant="h3">1. FILE UPLOAD</Typography>
            <Typography
              className={classes.subTitle}
              component={Box}
              mt={2}
              variant="subtitle2"
              gutterBottom
            >
              Using Retailer sales data, please upload by item, by store, by
              week sales data for campaign period weeks. Our Machine Learning
              Algorithms will use this data (in conjunction with the campaign
              details) to provide sales lift results.
            </Typography>
            <Box display="flex" alignItems="flex-end" mb={3}>
              <TextField
                className={classes.fileUploadField}
                inputProps={{
                  accept:
                    // CSV, XLS, XLSX
                    ".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
                label="Upload Data File"
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                InputLabelProps={{ shrink: true }}
                required
                variant="outlined"
              />
              <Box ml={3} mb={1}>
                <Typography
                  className={classes.boldText}
                  component={Box}
                  mb={2}
                  variant="caption"
                >
                  Optional:
                </Typography>
                <Button
                  className={classes.greyButton}
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Edit Field Names
                </Button>
              </Box>
            </Box>
            <Typography variant="body1">
              Please note that uploading large files may take a few moments. Do
              not close this window or tab while it is loading.
            </Typography>
            <Box display="flex" mt={5}>
              <Box mr={2}>
                <Button
                  classes={{
                    contained: classes.helpButton,
                  }}
                  variant={displayHelpText ? "contained" : "outlined"}
                  size="large"
                  onClick={() => {
                    setDisplayHelpText(!displayHelpText);
                  }}
                >
                  Need Help
                </Button>
              </Box>
            </Box>
            {displayHelpText && (
              <Paper
                mt={3}
                className={classes.tooltipPaper}
                width={750}
                alignSelf="center"
                display="flex"
                alignItems="flex-start"
                py="20px"
                pr={5}
              >
                <Danger className={classes.tooltipIcon} />
                <Box>
                  <Typography variant="h3" className={classes.tooltipText}>
                    File Upload Information:
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.smallGutterBottom}
                  >
                    .xls, .xlsx, and .csv files are all acceptable formats
                  </Typography>

                  <Typography variant="body1">
                    At minimum, uploaded sales data must include the $ Sales by
                    item/location/week.
                  </Typography>
                  <Typography variant="body1">
                    The by item/location should be rows, and the by week sales
                    data the columns.
                  </Typography>
                  <Typography variant="body1">
                    Location should include unique store or market
                    number/indicator, Street Address, City, State, and Zip Code.
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.smallGutterBottom}
                  >
                    Ideally, we would have at least 52 weeks worth of sales
                    data, but need at least 3 months prior for EXISTING items.
                  </Typography>
                  <Typography
                    variant="h3"
                    className={clsx(
                      classes.tooltipText,
                      classes.smallGutterBottom
                    )}
                  >
                    Walmart POS Data pull Retail Link Recipe example:
                  </Typography>
                  <Typography variant="body1">
                    Report Columns: UPC, Item Nbr, Item Desc 1, Store Nbr,
                    Street Address, City, State, Zip Code WM Week, Time Across
                    Data Across (Requires a selection from below), POS Sales,
                    POS Qty, Curr Str On Hand Qty, Curr Str In Transit Qty, Curr
                    Str In Whse Qty, Curr Str On Order Qty Vendor Nbr (6-digit)
                    Is One Of XXXXXX And Selections Include: By Fuzzy Dates —>
                    Time Range 1 TY Fiscal Year to Date Selections Include:
                    Store Type Breakdown —> All Stores, All Events
                  </Typography>
                </Box>
              </Paper>
            )}
          </Paper>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button
              onClick={trackerUtils.exitWizard}
              size="large"
              startIcon={<ArrowBackIosOutlinedIcon />}
            >
              PREVIOUS
            </Button>
            <Box display="flex">
              {campaignDetailsState.campaignDetails.roi_state !==
                "CONFIRMED" && (
                <Box mr={3}>
                  <Button
                    onClick={trackerUtils.exitWizard}
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.outlinedButtonOnGray}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
      <EditFieldNamesModal
        open={modalOpen}
        setOpen={setModalOpen}
        setFieldNames={setFieldNames}
        savedFieldNames={fieldNames}
      />
    </>
  );
}
