import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { useTrackerContext } from "context/roiTracker";
import { Paper } from "atoms";
import { useTotalOrNoMeasurableLift } from "utils/formatters";
import { SalesDataChart } from "atoms/campaign";

import Header from "./Header";
import ROIResultsFilters from "./ROIResultsFilters";
import ItemPerformanceCategory from "./ItemPerformanceCategory";
import PerformanceTotalsTable from "./PerformanceTotalsTable";

import StackedPerformanceHighlight from "./StackedPerformanceHighlight";

export default function PostCampaignViewROIResultsPage() {
  const { campaignDetailsState } = useCampaignDetailsContext();
  const { trackerDispatch } = useTrackerContext();
  const totalOrNoMeasurableLift = useTotalOrNoMeasurableLift();

  const { salesData, roiResults } = campaignDetailsState;
  const { grand_totals } = roiResults || {};

  if (!roiResults || !salesData) {
    return <Typography variant="h1">Error Fetching Results.</Typography>;
  }

  return (
    <Box p={4}>
      <Header />
      <ROIResultsFilters setLoading={trackerDispatch.setLoading} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        my={3}
      >
        <StackedPerformanceHighlight
          bigNumber={totalOrNoMeasurableLift(
            grand_totals.dollars.lift_percent,
            "%"
          )}
          label="Total % Lift"
        />
        <StackedPerformanceHighlight
          bigNumber={totalOrNoMeasurableLift(
            grand_totals.dollars.lift_total,
            "$"
          )}
          label="Total $ Lift"
        />
        <StackedPerformanceHighlight
          bigNumber={totalOrNoMeasurableLift(grand_totals.roi)}
          label="ROI"
        />
      </Box>
      <Box>
        <Paper p={3} width="100%">
          <Box mb={5}>
            <Typography variant="h2">
              Test Control Details Comparison
            </Typography>
          </Box>
          <Box height={290} py={3}>
            <SalesDataChart height={263} initialDataSet="dollars" />
          </Box>
        </Paper>
      </Box>
      <Box width="100%">
        <ItemPerformanceCategory
          title="Feature Items"
          itemSet={roiResults.feature_items}
          totals={roiResults.feature_item_totals}
        />
        <ItemPerformanceCategory
          title="Halo Items"
          itemSet={roiResults.halo_items}
          totals={roiResults.halo_item_totals}
        />
        <PerformanceTotalsTable totals={roiResults.grand_totals} />
      </Box>
    </Box>
  );
}
