import React from "react";

import { useCampaignDetailsContext } from "context/campaignDetails";
import { useTrackerContext } from "context/roiTracker";
import { resetCampaignDetails } from "context/campaignDetails/actions";
import { ConfirmModal } from "atoms";

export default function RedoTestControlModal({ open, setOpen }) {
  const { trackerUtils } = useTrackerContext();
  const { campaignDetailsDispatch } = useCampaignDetailsContext();
  async function onConfirm() {
    trackerUtils.asyncCallbackThenSetView(
      async () => await campaignDetailsDispatch(resetCampaignDetails),
      "PRE_CAMPAIGN_LANDING_PAGE"
    );
  }
  return (
    <ConfirmModal
      ariaLabel="Confirm Override Store List"
      open={open}
      setOpen={setOpen}
      text="By clicking this, you will be taken back to beginning of Campaign Details. Current analysis and file uploads will be removed."
      onConfirm={onConfirm}
    />
  );
}
