import { logout as apiCall } from "utils/api/auth";
import { toast } from "react-toastify";

export default async function logout(authDispatch) {
  const [_, error] = await apiCall();
  if (!error) {
    authDispatch({
      type: "CLEAR_USER",
    });
    toast.success("You have been logged out.");
  } else {
    // TODO: Is there a better way to handle this?
    toast.danger("Logout failed.");
  }
}
