// Use with useReducer to make big forms!
// function handleChange(target, payload) {
//    inputsDispatch({
//      target,
//      payload,
//    });
//  }
export default function inputsReducer(currentState, action) {
  return {
    ...currentState,
    [action.target]: action.payload,
  };
}
